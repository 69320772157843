import './SideNavigationBar.scss';
import { BuildingIcon, PeopleIcon, DeliveryIcon, SettingsIcon } from '../icon';
import NavItem from '../elements/NavItem';
import { useConfigurationStore } from 'store';

function SideNavigationBar() {
  const {
    IsCustomerMasterVisible,
    IsWarehouseMasterVisible,
    IsDeliveriesVisible,
  } = useConfigurationStore(
    ({
      configurations: {
        IsCustomerMasterVisible,
        IsDeliveriesVisible,
        IsWarehouseMasterVisible,
      },
    }) => ({
      IsCustomerMasterVisible,
      IsWarehouseMasterVisible,
      IsDeliveriesVisible,
    })
  );

  const subRoutes = [];

  if (IsCustomerMasterVisible) {
    subRoutes.push({
      to: '/settings/customers',
      label: 'Customers',
      icon: <PeopleIcon />,
    });
  }

  if (IsWarehouseMasterVisible) {
    subRoutes.push({
      to: '/settings/warehouses',
      label: 'Warehouses',
      icon: <BuildingIcon />,
    });
  }

  return (
    <div className='nav-sidebar'>
      {IsDeliveriesVisible && (
        <NavItem to='/delivery' label='Deliveries' icon={<DeliveryIcon />} />
      )}
      {/* avoid showing settings menu when there is not sub routes */}
      {subRoutes.length > 0 && (
        <NavItem
          label='Settings'
          icon={<SettingsIcon />}
          subRoutes={subRoutes}
        />
      )}
    </div>
  );
}

export default SideNavigationBar;
