import {
  Route,
  createBrowserRouter,
  createRoutesFromElements,
} from 'react-router-dom';
import DeliveryDetails from 'views/pages/delivery/delivery-details';
import DeliveryList from 'views/pages/delivery/delivery-list';
import ReviewDelivery from 'views/pages/delivery/review-delivery';
import HomePage from 'views/pages';
import Layout from 'views/shared/layout';
import Warehouses from 'views/pages/settings/warehouses';
import CustomerList from 'views/pages/settings/customers/customer-list';

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path='/' element={<Layout />}>
      <Route path='/' element={<HomePage />} />
      <Route path='delivery' element={<DeliveryList />} />
      <Route path='delivery/new' element={<DeliveryDetails />} />
      <Route path='delivery/:delivery_id/review' element={<ReviewDelivery />} />
      <Route path='delivery/:delivery_id' element={<DeliveryDetails />} />
      <Route path='settings' />
      <Route path='settings/customers' element={<CustomerList />} /> 
      <Route path='settings/warehouses' element={<Warehouses />} /> 
    </Route>
  )
);

export { router };
