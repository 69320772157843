import { ChangeEvent, useEffect, useState } from 'react';
import { Transporter } from 'models';
import { SearchableDropDown, SearchableDropDownProps } from 'views/components/dropdown';
import { useVehicleStore } from 'store/vehicle';

interface TransporterDropDownProps {
  required?: boolean;
  onChange: (newValue: Transporter | null) => void; // Pass this prop to let Formik know about the selection
}

export function TransporterSearchableDropDown({
  required,
  onChange,
}: TransporterDropDownProps) {
  const { selectedTransporter, setSelectedTransporter, fetchTransporters, transporterList } = useVehicleStore();
  const [options, setOptions] = useState<Transporter[]>([]);

  const handleSearch = async (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const query = event.target.value?.toLowerCase();
    if (!query) {
      return setOptions([]);
    }

    const { failed, data } = await fetchTransporters({ query });
    const filterCreatedTransports = transporterList.filter(
      ({name}) => name.toLowerCase().includes(query)
    );
    setOptions(failed ? [...filterCreatedTransports]: [...filterCreatedTransports, ...data]);
  };

  const handleChange = (_event: any, newValue: Transporter | null, reason?: string) => {
    if (reason === 'clear') {
      setOptions([]);
      setSelectedTransporter(null);
      onChange(null); 
      return;
    }

    if (newValue) {
      setOptions([newValue]);
      setSelectedTransporter(newValue);
      onChange(newValue);
    }
  };

  useEffect(() => {
    setOptions([...transporterList]);
  }, [transporterList]);

  useEffect(() => {
    setOptions(selectedTransporter ? [selectedTransporter]: []);
  }, [selectedTransporter]);

  const props: SearchableDropDownProps<Transporter> = {
    value: selectedTransporter,
    required: Boolean(required),
    handleSearch,
    onChange: handleChange,
    options,
    label: 'Transporter',
    debounce: 400,
    isOptionEqualToValue: (option, value) =>
      option.name === value.name,
    getOptionLabel: option => option.name,
    onBlur: () => setOptions(selectedTransporter ? [selectedTransporter]: []),
  };

  return <SearchableDropDown {...props}/>;
}
