import './UserForm.scss';
import {
  Button,
  Form,
  Input,
  PopoverWithArrow,
} from 'views/components/elements';
import {
  FormField,
  Required,
  Actions,
  Content,
  CreateNewLink,
} from '../StyledComponent';
import TransporterForm from './Transporter';
import { ChangeEvent, useState } from 'react';
import { NewVehicle, Transporter } from 'models';
import {
  removeExtraSpace,
  VehicleValidationSchema,
} from 'views/shared/utils/form-validator';
import { TransporterSearchableDropDown } from '../dropdowns/TransporterSearchableDropDown';
import { VehicleFormData } from 'models/Form';
import { useVehicleStore } from 'store/vehicle';
import { ErrorMessage, FormikErrors } from 'formik';

const initialValues = {
  name: '',
  number: '',
  vehicle_tonnage: '',
  transporter: '',
};

interface VehicleFormProps {
  onSubmit: (newVehicle: NewVehicle) => Promise<void>;
  onClose: () => void;
  onTransporterFormSubmit: (newTransporter: Transporter) => Promise<boolean>;
}

const VehicleForm = ({
  onSubmit,
  onTransporterFormSubmit,
  onClose,
}: VehicleFormProps) => {
  const [anchorElForTransporterForm, setAnchorElForTransporterForm] =
    useState<HTMLElement | null>(null);

  const { selectedTransporter } = useVehicleStore();

  const handleTransporterForm = async (
    newTransporter: Transporter,
    setFieldValue: (field: string, value: any) => void
  ) => {
    const failed = await onTransporterFormSubmit(newTransporter);
    if (failed) {
      return;
    }
    setFieldValue('transporter', newTransporter);
    setAnchorElForTransporterForm(null); 
  };

  const handleVehicleFormSubmit = async (formData: VehicleFormData) => {
    const newVehicle: NewVehicle = {
      name: removeExtraSpace(formData.name),
      number: removeExtraSpace(formData.number),
      vehicle_tonnage: removeExtraSpace(formData.vehicle_tonnage) || null,
      transporter: {
        ...selectedTransporter,
      }
    };

    onSubmit(newVehicle);
  };

  const handleOnChange =
    (
      setFieldTouched: (
        field: string,
        isTouched?: boolean,
        shouldValidate?: boolean
      ) => Promise<void | FormikErrors<VehicleFormData>>,
      setFieldValue: (
        field: string,
        value: any,
        shouldValidate?: boolean
      ) => Promise<void | FormikErrors<VehicleFormData>>
    ) =>
      (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const { name, value } = event.target;
        setFieldTouched(name, true);
        setFieldValue(name, value);
      };

  return (
    <Form
      className='user-form'
      initialValues={initialValues}
      onSubmit={handleVehicleFormSubmit}
      validationSchema={VehicleValidationSchema}
      validateOnChange={true}
      validateOnBlur={true}
    >
      {({ errors, setFieldTouched, touched, setFieldValue }) => (
        <>
          <Content className='scrollable-view'>
            <FormField className='form-group'>
              <label>
                Vehicle No. <Required>*</Required>
              </label>
              <Input
                name='number'
                placeholder='Vehicle No.'
                className={touched.number && errors.number ? 'error-input' : ''}
                onChange={handleOnChange(setFieldTouched, setFieldValue)}
              />
              <ErrorMessage name='number' component='span' className='error' />
            </FormField>
            <FormField className='form-group'>
              <label>
                Vehicle Name <Required>*</Required>
              </label>
              <Input
                name='name'
                placeholder='Vehicle Name'
                className={touched.name && errors.name ? 'error-input' : ''}
                onChange={handleOnChange(setFieldTouched, setFieldValue)}
              />
              <ErrorMessage name='name' component='span' className='error' />
            </FormField>
            <FormField className='form-group'>
              <label>
                Vehicle Tonnage
              </label>
              <Input
                name='vehicle_tonnage'
                placeholder='Vehicle Tonnage'
                className={touched.vehicle_tonnage && errors.vehicle_tonnage ? 'error-input' : ''}
                onChange={handleOnChange(setFieldTouched, setFieldValue)}
              />
              <ErrorMessage name='vehicle_tonnage' component='span' className='error' />
            </FormField>
            <FormField className='form-group'>
              <label>
                Transporter <Required>*</Required>
              </label>
              <TransporterSearchableDropDown
                onChange={(newValue: Transporter | null) => {
                  setFieldTouched('transporter', true);
                  setFieldValue('transporter', newValue);
                }}
              />
              <ErrorMessage
                name='transporter'
                component='span'
                className='error'
              />
              <PopoverWithArrow
                anchorEl={anchorElForTransporterForm}
                setAnchorEl={setAnchorElForTransporterForm}
                content={
                  <TransporterForm
                    onSubmit={(newTransporter) =>
                      handleTransporterForm(newTransporter, setFieldValue)
                    }
                    onCancel={() => setAnchorElForTransporterForm(null)}
                  />
                }
              >
                <CreateNewLink style={{position: 'relative'}}>Create New</CreateNewLink>
              </PopoverWithArrow>
            </FormField>
          </Content>
          <Actions className='btn-container flex-justify-end'>
            <Button type='submit'>Save</Button>
            <Button
              variant='text'
              color='secondary'
              className='cancel-btn'
              onClick={onClose}
            >
              Discard
            </Button>
          </Actions>
        </>
      )}
    </Form>
  );
};

export default VehicleForm;
