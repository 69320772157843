import axios, { AxiosRequestConfig as BaseConfig, AxiosResponse, AxiosError } from 'axios';
import { ErrorResponse } from 'models';
import qs from 'qs';
import { addEvent, removeEvent } from 'store';

interface AxiosRequestConfig<D = any> extends BaseConfig<D> {
  skipSpinner?: boolean
}

const client = axios.create({
  headers: { 'X-CSRF': 1 },
  paramsSerializer: (params) => qs.stringify(params, { arrayFormat: 'repeat' })
});

client.interceptors.request.use(config => {
  if (!(config as AxiosRequestConfig).skipSpinner) {
    addEvent(config.url);
  }
  return config;
}, error => Promise.reject(error));

client.interceptors.response.use(response => {
  if (!(response.config as AxiosRequestConfig).skipSpinner) {
    removeEvent(response.config.url);
  }
  return response;
}, error => {
  if (error instanceof AxiosError) {
    if (!(error.config as AxiosRequestConfig).skipSpinner) {
      removeEvent(error.config.url);
    }
  }
  return Promise.reject(error);
});

async function sendRequest<T>(fetchPromise: Promise<AxiosResponse<T>>): Promise<T> {
  try {
    const response = await fetchPromise;

    if (!response) {
      throw new Error('Request failed to be sent');
    }

    return response.data;
  } catch (error) {
    if (error instanceof AxiosError) {
      if ([400, 404].includes(error?.response?.status)) {
        return error.response.data;
      } else {
        return {
          succeeded: false,
          errors: [
            {
              code: 'BadGateway',
              description: 'Invalid request. Please try again after sometime.'
            }
          ]
        } as ErrorResponse as T;
      }
    }
  }
}

function getRequest<T>(path: string, config?: AxiosRequestConfig) {
  return client.get<T>(path, config);
}

function postRequest<T, R = NonNullable<unknown>>(path: string, data: R, config?: AxiosRequestConfig<R>) {
  return client.post<T>(path, data, config);
}

function putRequest<T, R = NonNullable<unknown>>(path: string, data: R, config?: AxiosRequestConfig<R>) {
  return client.put<T>(path, data, config);
}

function deleteRequest<T>(path: string, config?: AxiosRequestConfig) {
  return client.delete<T>(path, config);
}

function patchRequest<T, R = NonNullable<unknown>>(path: string, data: R, config?: AxiosRequestConfig<R>) {
  return client.patch<T>(path, data, config);
}

export { sendRequest, getRequest, postRequest, putRequest, deleteRequest, patchRequest };
