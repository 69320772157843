import { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import IconButton from '@mui/material/IconButton';
import { Replay as RefreshIcon, Search as SearchIcon } from '@mui/icons-material';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { Action } from 'views/components/styled';
import { useCustomerStore } from 'store/customer';
import { Button, Column, Form, Input, Table } from 'views/components/elements';
import { EditIcon } from 'views/components/icon';
import { CircularProgress } from '@mui/material';
import { Customer } from 'models';
import CustomerAdd from './customer-add'; 
import CustomerEdit from './customer-edit';
import WarningCard from 'views/components/elements/WarningCard';

function CustomerList() {
  const {
    customers,
    fetching,
    fetchCustomersPaginated,
    hasNextPage,
    filter,
    error,
    setError
  } = useCustomerStore();
  const location = useLocation();
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(location.search);
  const customerId = queryParams.get('customer_id');
  const isAdd = queryParams.get('add') === 'true';
  const query = queryParams.get('query');
  const formRef = useRef(null);

  const [sortConfig, setSortConfig] = useState({
    field: '', order: null
  });

  const isResetDisabled = !query && !customerId && !isAdd;
  // fetch data for the first time
  useEffect(() => {
    if (customers.length === 0) {
      fetchCustomersPaginated();
    }
  }, []);

  // refresh after customer added / edited
  useEffect(() => {
    fetchCustomersPaginated({ query }, true);
  }, [isAdd, customerId, query]);

  const columns = [
    { field: 'code', label: 'Customer Code', sortable: true, width: 120, autoResize: false },
    { field: 'name', label: 'Customer Name', width: 140, autoResize: false },
    { field: 'phone', label: 'Phone', width: 120, autoResize: false },
    { field: 'gst_number', label: 'GST Number', width: 120, autoResize: false },
    { field: 'lead_days', label: 'Lead Days', width: 120, autoResize: false },
    { field: 'shipping_address', label: 'Shipping Location', width: 220, autoResize: false },
    {
      field: 'action',
      label: 'Action',
      valueGetter: row => (
        <IconButton onClick={() => handleEdit(row.customer_id)}>
          <EditIcon />
        </IconButton>
      ),
      width: 80,
      autoResize: false
    },
  ] as Column<Customer>[];

  const handleAdd = () => {
    queryParams.set('add', 'true');
    navigate(`/settings/customers?${queryParams.toString()}`);
  };

  const handleEdit = (customerId: string) => {
    queryParams.set('customer_id', customerId);
    navigate(`/settings/customers?${queryParams.toString()}`);
  };

  const handleSearch = (values: typeof filter) => {
    queryParams.set('query', values.query);
    queryParams.set('page', '1');
    navigate(`/settings/customers?${queryParams.toString()}`);
  };

  // Clear all query params and navigate to the default route
  const handleReset = () => {
    if (formRef.current) {
      formRef.current.resetForm({ values: { query: '' } }); 
    }
    navigate('/settings/customers'); // This will remove all query params
  };

  const handleNextPage = () => {
    const newFilter = Object.assign({}, filter, {
      page: filter.page + 1,
    });

    fetchCustomersPaginated(newFilter);
  };

  const sortedCustomers = [...customers].sort((a, b) => {
    if (a[sortConfig.field] < b[sortConfig.field]) return sortConfig.order === 'asc' ? -1 : 1;
    if (a[sortConfig.field] > b[sortConfig.field]) return sortConfig.order === 'asc' ? 1 : -1;
    return 0;
  });

  return (
    <div className='custom-table-wrapper'>
      <WarningCard
        open={error}
        onClose={() => setError(null)}
        message={error}
        severity='error'
        durationInSeconds={10}
      />
      <h1>Customers</h1>
      <Action>
        <Form
          innerRef={formRef} 
          initialValues={{ query }}
          onSubmit={handleSearch}
          validateOnChange={false}
        >
          <Input
            name='query'
            placeholder='Search Customers'
            startAdornment={<SearchIcon />}
          />
          <Button type='submit'>
            <SearchIcon />
          </Button>
          <Button
            variant='text'
            startIcon={
              <span id='package-icon'>
                <RefreshIcon />
              </span>
            }
            onClick={handleReset}
            disabled={isResetDisabled} 
          >
              Reset
          </Button>
        </Form>
        <div>
          <Button
            startIcon={<AddCircleOutlineIcon />}
            onClick={handleAdd}
          >
            Add Customer
          </Button>
        </div>
      </Action>

      {fetching && (
        <div className='spinner'>
          <CircularProgress />
        </div>
      )}

      <Table
        rows={sortedCustomers}
        columns={columns}
        stickyHeader
        infiniteScroll
        hasMoreRows={hasNextPage}
        sortingField={sortConfig.field}
        sortingOrder={sortConfig.order}
        onSortChange={(field, order) => setSortConfig({ field, order })}
        onNextPage={handleNextPage}
      />

      {!fetching && customers.length === 0 && (
        <div>No records found.</div>
      )}
      {isAdd && (
        <CustomerAdd/> 
      )}
      {customerId && (
        <CustomerEdit customerId={customerId}/>
      )}
    </div>
  );
}

export default CustomerList;