import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { ArrowDownIcon, ArrowUpIcon } from '../icon';

interface NestedNavProps {
    to?: string;
    label: string;
    icon: React.ReactNode;
    subRoutes?: (Omit<NestedNavProps, 'subRoutes'>)[];
}

const NavItem: React.FC<NestedNavProps> = ({
  to,
  label,
  icon,
  subRoutes
}) => {

  const isActive = (path) => path ? location.pathname.includes(path) : false;

  const isOpen = subRoutes && subRoutes.some(child => isActive(child.to));

  const [open, setOpen] = useState(false);

  const Component = subRoutes ? (props) => <div {...props} className={`nav-dropdown-header ${props.className}`} /> : NavLink;

  useEffect(() => {
    setOpen(isOpen);
  },[location.pathname]);

  return (
    <div className='nav-item'>
      <Component to={to || '#'} className='nav-link parent-nav' onClick={() => setOpen(prev => !prev)}>
        {icon}
        <span>{label}</span>
        {subRoutes && (open ? <ArrowUpIcon /> : <ArrowDownIcon />)}
      </Component>
      {
        subRoutes && open && <div className='child-menu'>
          {
            subRoutes.map((child, index) => {
              return (
                <NavLink to={child.to || '#'} key={index} className='nav-link child-nav'>
                  {child.icon}
                  <span>{child.label}</span>
                </NavLink>
              );
            })
          }
        </div>
      }
    </div>
  );
};

export default NavItem;
