import styled from '@emotion/styled';
import { Tooltip, Typography } from '@mui/material';
import { deletePallet, fetchPallets, generatePalletLabel, updatePalletStatus } from 'gateways/warehouse';
import { Pallet } from 'models';
import { memo, useEffect, useRef, useState } from 'react';
import { Button, Column, Table } from 'views/components/elements';
import ConfirmDialog from 'views/components/elements/ConfirmDialog';
import IOSSwitch from 'views/components/elements/CustomSwitch';
import PopUpDialog from 'views/components/elements/PopUpDialog';
import {
  DeleteIcon,
  EditPenIcon,
  PalletIcon,
  QRCodeIcon,
} from 'views/components/icon';
import { FlexColumn, FlexRow } from 'views/components/styled';
import { Actions, TitleText } from 'views/pages/delivery/StyledComponent';
import { handleError } from 'views/shared/utils/error-utils';
import { PalletForm } from 'views/pages/settings/forms';
import PdfViewer from 'views/components/elements/PdfViewer';

interface PalletListProps {
  warehouse_id: number;
  onDiscard: () => void;
  onError: (error: string) => void;
}

interface PalletTableNotesProps {
  is_empty: boolean;
  is_active: boolean;
  onPalletDelete: () => void;
  onPalletEdit: () => void;
  onPalletToggle: (enable: boolean) => void;
}

type DialogConfigOptions = {
  isOpen?: boolean;
  message?: string;
  type?: 'pallet_delete' | 'pallet_status_update';
  data?: Partial<Pallet>;
  pallet?: boolean;
  isEditForm?: boolean;
  title?: string;
}
const defaultDialogConfig: DialogConfigOptions = {
  isOpen: false,
  message: '',
  data: {},
};

const StyledButton = styled(Button)({
  marginLeft: 'auto',
});

const PalletNotes: React.FC<PalletTableNotesProps> = ({
  is_empty,
  is_active,
  onPalletDelete,
  onPalletEdit,
  onPalletToggle,
}) => (
  <FlexRow>
    <Typography
      style={{ fontWeight: 'normal', fontSize: '12px', flex: 1 }}
      className='truncate'
    >
      {is_empty ? 'Yes' : 'No'}
    </Typography>
    <FlexRow>
      <IOSSwitch
        checked={is_active}
        size='small'
        sx={{ transform: 'scale(0.75)' }}
        onClick={(event) => event.stopPropagation()}
        onChange={(event) => {
          const enable = event.target.checked;
          onPalletToggle(enable);
        }
        }
      />
      <Button
        variant='text'
        endIcon={<EditPenIcon />}
        onClick={(event) => {
          event.stopPropagation();
          onPalletEdit();
        }
        }/>
      <Button
        variant='text'
        style={{ fontWeight: 'normal', fontSize: '12px' }}
        endIcon={<DeleteIcon />}
        onClick={(event) => {
          event.stopPropagation();
          onPalletDelete();
        }}
      />
    </FlexRow>
  </FlexRow>
);

const fetchPalletDetails = async (pallet_id: number) => {
  const response = await fetchPallets(pallet_id);
  return handleError<Pallet[]>(response);
};

const deletePalletById = async (pallet_id: number) => {
  const response = await deletePallet(pallet_id);
  return handleError<undefined>(response);
};

const enableOrDisablePalletById = async (pallet_id: number, is_active: boolean) => {
  const response = await updatePalletStatus(pallet_id, is_active);
  return handleError<undefined>(response);
};

const PalletListComp: React.FC<PalletListProps> = ({
  warehouse_id,
  onError,
  onDiscard,
}) => {
  const [pallets, setPallets] = useState<Pallet[]>([]);
  const [isFetched, setIsFetched] = useState<boolean>();
  const isInitialFetchCompleted = useRef(null);
  const [popUpDialogConfig, setPopUpDialogConfig] =
    useState<DialogConfigOptions>(defaultDialogConfig);
  const [selectedPallets, setSelectedPallets] = useState<Pallet[]>([]);

  useEffect(() => {
    (async () => {
      if (!isInitialFetchCompleted.current) {
        isInitialFetchCompleted.current = true;
        const { failed, data } = await fetchPalletDetails(warehouse_id);

        setIsFetched(true);
        if (failed) {
          return onError('Failed to fetch pallets. Please try again.');
        }

        setPallets(data);
      }
    })();
  }, []);

  const removePallet = async (pallet_id: number) => {
    const { failed, error } = await deletePalletById(pallet_id);

    if (failed) {
      return onError(error);
    }

    const pltFilter = (pallets: Pallet[]) =>
      pallets.filter(function palletFilter(pallet) {
        return pallet.pallet_id !== pallet_id;
      });

    setPallets(pltFilter);
    setSelectedPallets(pltFilter);
  };

  const onPalletDelete = (pallet_id: number) => () => {
    setPopUpDialogConfig({
      isOpen: true,
      message: 'Are you sure you want to delete this pallet?',
      type: 'pallet_delete',
      data: {
        pallet_id,
      },
    });
  };

  const enableOrDisablePallet = async (pallet_id: number, is_active: boolean) => {
    const { failed, error } = await enableOrDisablePalletById(pallet_id, is_active);

    if (failed) {
      return onError(error);
    }

    const pltMapper = (prev: Pallet[]) => prev.map(function (plt) {
      const is_active = this.pallet_id === plt.pallet_id ? this.is_active: plt.is_active;
      return {...plt, is_active};
    }, {pallet_id, is_active});

    setPallets(pltMapper);
    setSelectedPallets(pltMapper);
  };

  const onPalletStatusChange = (pallet_id: number) => (enable: boolean) => {
    setPopUpDialogConfig({
      isOpen: true,
      message: `Are you sure you want to ${enable ? 'enable' : 'disable'} this pallet?`,
      type: 'pallet_status_update',
      data: {
        pallet_id,
        is_active: enable,
      },
    });
  };

  // Pop up functionality goes here
  const handlePopUp =
    (type: 'open' | 'close', isEdit?: boolean, data?: Partial<Pallet>) => () => {
      const title = `${isEdit ? 'Edit' : 'Create'} Pallet`;

      setPopUpDialogConfig({
        isEditForm: Boolean(isEdit),
        pallet: type === 'open',
        title,
        data,
      });
    };

  const onClickCancel = () => setPopUpDialogConfig({ ...defaultDialogConfig });
  const onClickConfirm =
    (type: 'pallet_delete' | 'pallet_status_update', data: Partial<Pallet>) =>
      async () => {
        const { pallet_id, is_active } = data;

        switch (type) {
        case 'pallet_delete':
          await removePallet(pallet_id);
          break;
        case 'pallet_status_update':
          await enableOrDisablePallet(pallet_id, is_active);
          break;
        }

        onClickCancel();
      };

  // Table settings and functionality goes here
  const columns: Column<Pallet>[] = [
    {
      field: 'pallet_code',
      label: 'Pallet Code',
      width: 120,
    },
    {
      field: 'notes',
      label: 'Notes',
      autoResize: false,
      width: 600,
      valueGetter({ notes }) {
        return (
          <Tooltip title={notes ?? ''} placement='bottom-start'>
            <span>{notes ?? '-'}</span>
          </Tooltip>
        );
      },
    },
    {
      field: 'is_empty',
      label: 'Is Empty',
      valueGetter({ pallet_id, is_active, is_empty, ...etc }) {
        return (
          <PalletNotes
            is_empty={is_empty}
            is_active={is_active}
            onPalletDelete={onPalletDelete(pallet_id)}
            onPalletToggle={onPalletStatusChange(pallet_id)}
            onPalletEdit={() => {
              const pallet = { pallet_id, is_active, is_empty, ...etc };
              handlePopUp('open', true, pallet)();
            }}
          />
        );
      },
    },
  ];
  const onSelectionChange = (rows: Pallet[]) => setSelectedPallets([...rows]);
  const renderTable = () => {
    if (pallets.length) {
      return (
        <div className='custom-table-wrapper location-table-container' style={{ marginTop: '16px', maxHeight: '35vh' }}>
          <Table
            stickyHeader
            rows={pallets}
            selectable
            selected={selectedPallets}
            columns={columns}
            infiniteScroll
            onSelectionChange={onSelectionChange}
          />
        </div>
      );
    }

    return (
      <p style={{ textAlign: 'center' }}>
        No pallets found. Please create one by clicking on the <strong>Add Pallet</strong> icon.
      </p>
    );
  };

  // Generate QR logic. Will be implemented in the future.
  const [pdfViewerConfig, setPdfViewerConfig] = useState({
    pdfData: null,
    pdfTitle: null,
    open: false,
  });

  const handleClose = () => 
    setPdfViewerConfig({pdfData: null, pdfTitle: null, open: false});

  const generateQR = async () => {
    const pallet_ids = selectedPallets.map(({ pallet_id }) => pallet_id);

    if (!pallet_ids?.length) {
      return onError('Please select at least 1 pallet to generate QR.');
    }

    const response = await generatePalletLabel(pallet_ids);
    const { failed, error, data } = handleError<string>(response);

    if (failed) {
      return onError(error);
    }

    setPdfViewerConfig({
      pdfData: data,
      pdfTitle: 'Pallet Label',
      open: true,
    });
  };

  // pallet form logic goes here.
  const handleFormSubmit = (failed: boolean, error: string, pallet?: Pallet) => {
    if (failed) {
      return onError(error);
    }

    if (popUpDialogConfig.isEditForm) {
      setPallets((prev) => prev.map(function (plt) {
        return this.pallet_id === plt.pallet_id ? this : plt;
      }, {...pallet})
      );
    } else {
      setPallets((prev) => [...prev, pallet]);
    }

    handlePopUp('close', false)();
  };

  return (
    <FlexColumn>
      <FlexRow style={{ marginBottom: '1rem' }}>
        <TitleText>Pallets</TitleText>
        <FlexRow style={{ marginLeft: 'auto' }}>
          <StyledButton variant='contained' startIcon={<QRCodeIcon />} onClick={generateQR}>
            Generate QR
          </StyledButton>
          <StyledButton variant='contained' startIcon={<PalletIcon />} onClick={handlePopUp('open', false, { warehouse_id })}>
            Add Pallet
          </StyledButton>
        </FlexRow>
      </FlexRow>
      {isFetched && renderTable()}
      <Actions className='btn-container'>
        <StyledButton
          variant='text'
          color='secondary'
          className='cancel-btn'
          onClick={onDiscard}
        >
          Discard
        </StyledButton>
      </Actions>
      <PopUpDialog
        open={Boolean(popUpDialogConfig.pallet)}
        onClose={handlePopUp('close')}
        title={popUpDialogConfig.title}
        sx={{alignSelf: 'baseline'}}
      >
        <PalletForm
          value={popUpDialogConfig.data as Pallet}
          onSubmit={handleFormSubmit}
          onClose={handlePopUp('close')}
          edit={popUpDialogConfig.isEditForm}
        />
      </PopUpDialog>
      <ConfirmDialog
        isOpen={Boolean(popUpDialogConfig.isOpen)}
        confirmationMsg={popUpDialogConfig.message}
        onClickConfirm={() =>
          onClickConfirm(popUpDialogConfig.type, popUpDialogConfig.data)()
        }
        onClose={onClickCancel}
      />
      <PdfViewer {...pdfViewerConfig} handleClose={handleClose} />
    </FlexColumn>
  );
};

const PalletList = memo(
  PalletListComp,
  (prev, next) =>
    prev.warehouse_id === next.warehouse_id &&
    prev.onError === next.onError &&
    prev.onDiscard === next.onDiscard
);

export { PalletList };
