import { useEffect, useRef, useState } from 'react';
import {
  Button,
  Input,
  PopoverWithArrow,
  Textarea,
} from 'views/components/elements';
import { ContentWrapper } from 'views/components/styled';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Customer, DeliveryLine, NewVehicle, NewVehicleOperator, Transporter, ValidateDelivery, Vehicle, VehicleOperator } from 'models';
import {
  FormField,
  Required,
  Actions,
  Content,
  Row,
  CreateNewLink,
} from './StyledComponent';
import UserForm from './forms/UserForm';
import { useWarehouseStore } from 'store/warehouse';
import { DELIVERY_STATUS } from 'views/shared/utils/delivery-utils';
import VehicleForm from './forms/VehicleForm';
import { useOperatorStore } from 'store/operator';
import { useVehicleStore } from 'store/vehicle';
import { useCustomerStore } from 'store/customer';
import { CustomerSearchableDropDown } from './dropdowns/CustomerSearchableDropDown';
import { OperatorSearchableDropDown } from './dropdowns/OperatorSearchableDropDown';
import { VehicleSearchableDropDown } from './dropdowns/VehicleSearchableDropDown';
import WarningCard from 'views/components/elements/WarningCard';
import {
  removeExtraSpace,
  deliveryValidationSchema,
} from 'views/shared/utils/form-validator';
import { Formik, FormikHelpers } from 'formik';
import './forms/UserForm.scss';
import { validateDelivery } from 'gateways/warehouse';
import { handleError as handleApiError } from '../../shared/utils/error-utils';
import { dateTimeFormat, getTodayDate } from 'views/shared/utils/string-utils';

interface DeliveryFormValues {
  invoice_number: string;
  customer: Customer;
  shipping_address: string;
  total_cases: string | number;
  notes?: string;
  sku: string;
  vehicle: Vehicle;
  status: string;
  vehicle_operator: VehicleOperator;
  delivery_id: string;
  business_id: string;
  created_date_time: string;
  delivery_location?: string;
  pod_image_link?: string;
  lr_image_link?: string;
  lines: DeliveryLine[];
  delivery_date?: string;
}

export default function DeliveryDetails() {
  const { delivery_id } = useParams();
  const navigate = useNavigate();
  const { state: locationState } = useLocation();

  const [anchorElForUserForm, setAnchorElForUserForm] =
    useState<HTMLElement | null>(null);
  const [anchorElForVehicleForm, setAnchorElForVehicleForm] =
    useState<HTMLElement | null>(null);

  const isEdit = Boolean(delivery_id);
  const [initialState, setInitialState] = useState(true);
  const redirectToViewPage = (delivery_id: string) =>
    locationState?.redirectToViewPage ?? `/delivery/${delivery_id}/review`;
  const redirectToDeliveryListPage = locationState?.redirectToDeliveryListPage ?? '/delivery';

  const {
    delivery,
    createDelivery,
    fetchDelivery,
    updateDelivery,
    resetData,
    setDelivery,
  } = useWarehouseStore();

  const {
    setSelectedVehicle,
    resetVehicleData,
    selectedVehicle,
    addTransporter,
    addVehicle,
  } = useVehicleStore();

  const { setSelectedOperator, addOperator, resetOperatorData } = useOperatorStore();
  const { setSelectedCustomer, resetCustomerData } = useCustomerStore();

  const [isErrorPopUpOpen, setIsErrorPopUpOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const isInitialFetchCompleted = useRef(false);

  useEffect(() => {
    const fetchData = async () => {
      if (isEdit && !isInitialFetchCompleted.current) {
        isInitialFetchCompleted.current = true;
        await fetchDelivery(delivery_id);
        setInitialState(false);
      }
    };

    fetchData();

    return () => {
      resetData();
      resetCustomerData();
      resetVehicleData();
      resetOperatorData();
    };
  }, []);

  useEffect(() => {
    if (initialState && isEdit) {
      if (delivery?.customer?.customer_id) {
        setSelectedCustomer(delivery.customer);
      }

      if (delivery?.vehicle?.vehicle_id) {
        setSelectedVehicle(delivery.vehicle);
      }

      if (delivery?.vehicle_operator?.vehicle_operator_id) {
        setSelectedOperator(delivery?.vehicle_operator);
      }
    }

    if (delivery?.status && delivery.status === DELIVERY_STATUS[DELIVERY_STATUS.delivered]) {
      navigate(`/delivery/${delivery_id}/review`);
    }
  }, [delivery, initialState, isEdit]);

  const handleCancel = (formName: string) => () => {
    const redirectTo = isEdit ? redirectToViewPage(delivery_id) : redirectToDeliveryListPage;

    if (formName === 'user') {
      setAnchorElForUserForm(null);
    } else if (formName === 'vehicle') {
      setAnchorElForVehicleForm(null);
    } else {
      navigate(redirectTo, {
        state: {
          redirectToViewPage: redirectToViewPage(delivery_id),
          redirectToDeliveryListPage,
        },
      });
    }
  };

  const handleSubmit = async (
    values: DeliveryFormValues,
    { setSubmitting }: FormikHelpers<DeliveryFormValues>
  ) => {
    const { invoice_number, total_cases, notes, shipping_address, delivery_date } = values;
    const {
      customer: { customer_id },
      status,
    } = delivery;
    let { delivery_id } = delivery;

    const vehicle = (delivery?.vehicle?.vehicle_id || delivery.vehicle?.name) ? delivery.vehicle : undefined;
    const vehicle_operator = (delivery?.vehicle_operator?.vehicle_operator_id || delivery.vehicle_operator?.name) ? delivery.vehicle_operator : undefined;

    const data = {
      invoice_number,
      customer_id,
      delivery_location: removeExtraSpace(shipping_address) || null,
      vehicle,
      vehicle_operator,
      status_id: isEdit ? DELIVERY_STATUS[status] : 1,
      total_cases: +total_cases,
      notes: removeExtraSpace(notes),
      delivery_date,
    };

    if (isEdit) {
      const { failed, error } = await updateDelivery({ ...data, delivery_id });
      if (failed) {
        return handleError(error);
      }
    } else {
      const {
        failed,
        data: deliveryData,
        error,
      } = await createDelivery(data as any);

      if (failed) {
        return handleError(error);
      } else {
        delivery_id = deliveryData.delivery_id;
      }
    }

    setSubmitting(false);
    navigate(redirectToViewPage(delivery_id), {
      state: {
        redirectToDeliveryListPage,
        redirectToViewPage: redirectToViewPage(delivery_id),
      },
    });
  };

  const delivery_date = dateTimeFormat(delivery.delivery_date || new Date(), 'yyyy-MM-DD') as string;

  const initialValues: DeliveryFormValues = {
    invoice_number: delivery.invoice_number || '',
    customer: delivery.customer || ({} as Customer),
    shipping_address: delivery.shipping_address || '',
    total_cases: delivery.total_cases || '',
    notes: delivery.notes || '',
    vehicle: delivery.vehicle || ({} as Vehicle),
    status: delivery.status || '',
    vehicle_operator: delivery.vehicle_operator || ({} as VehicleOperator),
    lines: delivery.lines || [],
    sku: '',
    delivery_id: '',
    business_id: '',
    created_date_time: '',
    delivery_date,
  };

  const handleError = (error: string) => {
    setErrorMessage(error);
    setIsErrorPopUpOpen(true);
  };

  const validateData = async (data: ValidateDelivery) => {
    const response = await validateDelivery(data);
    const { failed, error } =  handleApiError<undefined>(response);

    if (failed) {
      handleError(error);
    }

    return failed;
  };

  const handleUserFormSubmit = async (
    newVehicleOperator: NewVehicleOperator
  ) => {
    if (!selectedVehicle?.transporter?.name) {
      return handleError(
        'Vehicle must be selected before creating an operator.'
      );
    }

    const failed = await validateData({
      vehicle_operator_email: newVehicleOperator.email,
      vehicle_operator_phone: newVehicleOperator.phone,
    });

    if (failed) {
      return;
    }

    addOperator(newVehicleOperator);
    setDelivery({
      vehicle_operator: {
        ...newVehicleOperator,
      },
    });

    handleCancel('user')();
  };

  const handleTransporterFormSubmit = async (newTransporter: Transporter) => {
    const failed = await validateData({
      transporter_name: newTransporter.name
    });

    if (!failed) {
      addTransporter(newTransporter);
    }

    return failed;
  };

  const handleVehicleFormSubmit = async (newVehicle: NewVehicle) => {
    const failed = await validateData({
      vehicle_number: newVehicle.number,
    });

    if (failed) {
      return;
    }

    addVehicle(newVehicle);

    setDelivery({
      vehicle: {
        ...newVehicle,
      },
    });

    setSelectedOperator(null);
    handleCancel('vehicle')();
  };

  if (isEdit && !delivery.invoice_number) {
    return <></>;
  }

  const isPrintedOrShipped = Boolean(['shipped', 'printed'].find((status) => status === delivery?.status));

  return (
    <ContentWrapper>
      <WarningCard
        open={isErrorPopUpOpen}
        onClose={() => setIsErrorPopUpOpen(false)}
        message={errorMessage}
        severity='error'
      />
      <h1>{isEdit ? 'Edit' : 'Add'} Delivery</h1>

      <Formik
        initialValues={initialValues}
        validationSchema={deliveryValidationSchema}
        onSubmit={handleSubmit}
      >
        {({ handleSubmit, values, touched, errors, setFieldValue, setFieldTouched }) => (
          <form onSubmit={handleSubmit}>
            <Content>
              <Row>
                <FormField style={{ flex: 1 }}>
                  <label>
                    Customer <Required>*</Required>
                  </label>
                  <CustomerSearchableDropDown
                    required
                    setFieldValue={setFieldValue}
                    disabled={isPrintedOrShipped}
                  />
                </FormField>
                <FormField style={{ flex: 1 }}>
                  <label>
                    Invoice Number <Required>*</Required>
                  </label>
                  <Input
                    name="invoice_number"
                    placeholder="Invoice Number"
                    value={values.invoice_number}
                    disabled={isPrintedOrShipped}
                    aria-disabled={isPrintedOrShipped}
                    onChange={(e) => {
                      const { value } = e.currentTarget;
                      const regex = /^[a-zA-Z0-9,()-]*$/;
                      if (!regex.test(value)) {
                        setFieldValue('invoice_number', value.replace(/[^a-zA-Z0-9,()-]/g, ''));
                      } else if(value !=='') {
                        setFieldValue('invoice_number', value);
                      }
                      if(value === ''){
                        setFieldValue('invoice_number', value);
                        setFieldTouched('invoice_number', true);
                      }
                    }}
                    onBlur={() => setFieldTouched('invoice_number', true)}
                  />
                  {touched.invoice_number && errors.invoice_number && (
                    <span className="validation-error">
                      {errors.invoice_number}
                    </span>
                  )}
                </FormField>
              </Row>
              <Row>
                <FormField style={{ flex: 1 }}>
                  <label>Delivery Location</label>
                  <Input
                    name="shipping_address"
                    placeholder="Delivery Location"
                    value={values.shipping_address}
                    disabled={isPrintedOrShipped}
                    aria-disabled={isPrintedOrShipped}
                  />
                </FormField>
                <Row style={{ flex: 1 }}>
                  <FormField>
                    <label>Delivery Date <Required>*</Required></label>
                    <Input
                      type='date'
                      required={true}
                      name="delivery_date"
                      placeholder="Delivery Date"
                      value={values.delivery_date}
                      inputProps={{
                        min: getTodayDate(), 
                      }}
                    />
                  </FormField>
                  <FormField style={{ flex: 1 }}>
                    <label>
                      Total No.of Cases <Required>*</Required>
                    </label>
                    <Input
                      name="total_cases"
                      placeholder="Total No.of Cases"
                      type="number"
                      value={values.total_cases}
                      disabled={isPrintedOrShipped}
                      aria-disabled={isPrintedOrShipped}
                      onChange={(e) => {
                        const value = e.target.value;
                        // Convert value to a number, handle empty string
                        const numberValue: number | '' = value === '' ? '' : parseInt(value.replace(/[^0-9]/g, ''), 10);
                        // Apply min and max constraints only if numberValue is a number
                        if (typeof numberValue === 'number') {
                          if (numberValue < 1) {
                            setFieldTouched('total_cases', true);
                          } else if (numberValue > 32766) {
                            setFieldTouched('total_cases', true);
                          }
                        }
                        // Convert number back to string for Formik
                        setFieldValue('total_cases', numberValue === '' ? '' : numberValue.toString());
                        if(numberValue === ''){
                          setFieldValue('total_cases', value);
                          setFieldTouched('total_cases', true);
                        }
                      }}
                      onBlur={() => setFieldTouched('total_cases', true)}
                    />
                    {touched.total_cases && errors.total_cases && (
                      <span className="validation-error">
                        {errors.total_cases}
                      </span>
                    )}
                  </FormField>
                </Row>
              </Row>
              <Row>
                <FormField style={{ flex: 1 }}>
                  <label>
                    Vehicle { isPrintedOrShipped && <Required>*</Required>}
                  </label>
                  <VehicleSearchableDropDown required={isPrintedOrShipped} />
                  <PopoverWithArrow
                    anchorEl={anchorElForVehicleForm}
                    setAnchorEl={setAnchorElForVehicleForm}
                    content={
                      <VehicleForm
                        onSubmit={handleVehicleFormSubmit}
                        onClose={handleCancel('vehicle')}
                        onTransporterFormSubmit={handleTransporterFormSubmit} 
                      />
                    }
                  >
                    <CreateNewLink>Create New</CreateNewLink>
                  </PopoverWithArrow>
                </FormField>
                <FormField style={{ flex: 1 }}>
                  <label>
                    Driver {isPrintedOrShipped && <Required>*</Required> }
                  </label>
                  <OperatorSearchableDropDown required={isPrintedOrShipped} />
                  <PopoverWithArrow
                    anchorEl={anchorElForUserForm}
                    setAnchorEl={setAnchorElForUserForm}
                    content={
                      <UserForm
                        onSubmit={handleUserFormSubmit}
                        onCancel={handleCancel('user')}
                      />
                    }
                  >
                    <CreateNewLink>Create New</CreateNewLink>
                  </PopoverWithArrow>
                </FormField>
              </Row>
              <FormField>
                <label>Notes</label>
                <Textarea
                  name="notes"
                  placeholder="Notes"
                  value={values.notes}
                  onChange={(e) => {
                    const { value } = e.currentTarget;
                    const regex = /^[a-zA-Z\s]*$/;
                    if (!regex.test(value)) {
                      setFieldTouched('notes', true);
                    } else if(value !=='') {
                      setFieldValue('notes', value);
                    }
                    if(value === ''){
                      setFieldValue('notes', value);
                      setFieldTouched('notes', true);
                    }
                  }}
                  onBlur={() => setFieldTouched('notes', true)}
                />
                {touched.notes && errors.notes && (
                  <span className="validation-error">
                    {errors.notes}
                  </span>
                )}
              </FormField>
            </Content>
            <Actions className="btn-container">
              <Button type="submit">Review</Button>
              <Button
                variant="text"
                color="secondary"
                onClick={handleCancel('')}
                className="cancel-btn"
              >
                Discard
              </Button>
            </Actions>
          </form>
        )}
      </Formik>
    </ContentWrapper>
  );
}
