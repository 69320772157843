import { ApiParams, fetchCustomers, fetchCustomersList } from 'gateways/warehouse';
import { Customer } from 'models';
import { EntityResponse } from 'views/shared/utils/delivery-utils';
import { handleError } from 'views/shared/utils/error-utils';
import { removeEmptyValues } from 'views/shared/utils/string-utils';
import { create } from 'zustand';

type Action = {
  setSelectedCustomer: (customer: Customer) => void;
  fetchCustomers: (params?: ApiParams) => Promise<EntityResponse<Customer[]>>;
  fetchCustomersPaginated: (params?: ApiParams, refetch?: boolean) => Promise<void>;
  resetCustomerData: () => void;
  setError?: (error: any) => void;
};

type State = {
  selectedCustomer: Customer;
  customers: Customer[];
  hasNextPage: boolean;
  filter: ApiParams;
  fetching?: boolean;
  error?: any;
};

const useCustomerStore = create<State & Action>()((set) => ({
  customers: [], // all the paginated customer data will be stored here
  hasNextPage: false, // remove this if pagination feature is not required for sure
  filter: {},
  error: null,
  fetching: false,
  selectedCustomer: null,
  fetchCustomers: async (params?: ApiParams): Promise<EntityResponse<Customer[]>> => {
    const apiResponse = await fetchCustomers(params);
    
    const response = handleError<Customer[]>(apiResponse);

    if (response.failed) {
      return response;
    }

    const list = response.data.map(({customer_id, name, code, shipping_address}) => ({
      customer_id,
      name,
      code,
      shipping_address
    }));

    return {...response, data: list};
  },

  fetchCustomersPaginated: async (params?: ApiParams, refetch?: boolean): Promise<void> => {
    const apiParams = params ? (removeEmptyValues(params) || { page: 1, limit: 10 }) : { page: 1, limit: 10 };
    set(() => ({
      fetching: true,
    }));
    try {
      const apiResponse = await fetchCustomersList(apiParams);
      set(state => ({
        filter: apiParams,
        // for search, skip pagination
        customers: (apiParams.query || apiParams.page === 1 || refetch) ? apiResponse.collection : state.customers.concat(apiResponse.collection),
        hasNextPage: apiParams.page < apiResponse.metadata.last_page
      }));
    } catch (err) {
      set(() => ({
        error: 'Failed to fetch Customers'
      }));
    }
    set(() => ({
      fetching: false,
    }));
  },

  setSelectedCustomer: (selectedCustomer: Customer) => {
    set(() => ({
      selectedCustomer,
    }));
  },
  resetCustomerData: () => {
    set(() => ({
      selectedCustomer: null,
    }));
  },

  setError: (error: any) => {
    set(() => ({
      error
    }));
  }
}));

export { useCustomerStore };
