import PopUpDialog from './PopUpDialog';
import { Actions, Content } from 'views/pages/delivery/StyledComponent';
import { Typography } from '@mui/material';
import Button from './Button';

type ConfirmDialogProps = {
  confirmationTitle?: string;
  confirmationMsg: string;
  onClickConfirm: () => void;
  isOpen: boolean;
  onClose: () => void;
};

export default function ConfirmDialog({
  isOpen,
  onClose,
  confirmationMsg,
  confirmationTitle = 'Confirm!',
  onClickConfirm,
}: ConfirmDialogProps) {
  return (
    <PopUpDialog
      open={Boolean(isOpen)}
      onClose={onClose}
      title={confirmationTitle}
    >
      <Content>
        <Typography fontWeight='500'>{confirmationMsg}</Typography>
      </Content>
      <Actions className='btn-container flex-justify-end'>
        <Button onClick={onClickConfirm}>Yes</Button>
        <Button
          variant='text'
          color='secondary'
          className='cancel-btn'
          onClick={onClose}
        >
          Discard
        </Button>
      </Actions>
    </PopUpDialog>
  );
}
