import {
  createLocation,
  fetchLocations,
  updateLocation,
} from 'gateways/warehouse';
import { Location } from 'models';
import { EntityResponse } from 'views/shared/utils/delivery-utils';
import { handleError } from 'views/shared/utils/error-utils';
import { create } from 'zustand';

type Action = {
  createLocation: (location: Location) => Promise<EntityResponse<Location>>;
  updateLocation: (location: Location) => Promise<EntityResponse<Location>>;
  fetchLocations: () => Promise<EntityResponse<Location[]>>;
};

type State = {
  locationList: Location[];
  fetched: boolean;
  reset: () => void;
};

const useLocationStore = create<State & Action>()(set => ({
  locationList: [],
  fetched: false,
  fetchLocations: async () => {
    const response = await fetchLocations();
    const errorObj = handleError<Location[]>(response);

    if (!errorObj.failed) {
      set(state => ({
        ...state,
        fetched: true,
        locationList: [...state.locationList, ...errorObj.data],
      }));
    }

    return errorObj;
  },
  createLocation: async (
    location: Location
  ): Promise<EntityResponse<Location>> => {
    const response = await createLocation(location);

    const errorObj = handleError<Location>(response);
    if (!errorObj.failed) {
      set(state => ({
        ...state,
        locationList: [...state.locationList, { ...errorObj.data }],
      }));
    }

    return errorObj;
  },
  updateLocation: async (
    location: Location
  ): Promise<EntityResponse<Location>> => {
    const response = await updateLocation(location);

    const errorObj = handleError<Location>(response);
    if (!errorObj.failed) {
      set(state => ({
        ...state,
        locationList: [...state.locationList, { ...errorObj.data }],
      }));
    }

    return errorObj;
  },
  reset: () => {
    set({
      locationList: [],
      fetched: false,
    });
  },
}));

export { useLocationStore };
