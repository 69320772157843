import { useCustomerStore } from 'store/customer';
import { useWarehouseStore } from 'store/warehouse';
import { ChangeEvent, SyntheticEvent, useEffect, useState } from 'react';
import { Customer } from 'models';
import { SearchableDropDown, SearchableDropDownProps } from 'views/components/dropdown';

interface CustomerSearchableDropDownProps {
  required?: boolean;
  setFieldValue?: (field: string, value: any, shouldValidate?: boolean) => void;
  disabled?: boolean;
}

export function CustomerSearchableDropDown({
  required,
  setFieldValue,
  disabled,
}: CustomerSearchableDropDownProps) {
  const { setSelectedCustomer, selectedCustomer, fetchCustomers } =
    useCustomerStore();
  const { setDelivery } = useWarehouseStore();
  const [options, setOptions] = useState<Customer[]>([]);

  const handleSearch = async (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { value: query } = event.target;
    if (!query) {
      return setOptions([]);
    }

    const { failed, data } = await fetchCustomers({ query });
    setOptions(failed ? []: [...data]);
  };

  const onChange = (_: SyntheticEvent, newValue: Customer | null, reason?: string) => {
    if (reason === 'clear') {
      setOptions([]);
      setSelectedCustomer(null);
      setDelivery({
        customer: null,
        shipping_address: null,
      });
      if (setFieldValue) {
        setFieldValue('shipping_address', null);
      }
      return;
    }

    if (newValue) {
      setOptions([newValue]);
      setSelectedCustomer(newValue);
      setDelivery({
        customer: { ...newValue },
        shipping_address: newValue.shipping_address,
      });
      if (setFieldValue) {
        setFieldValue('shipping_address', newValue.shipping_address);
      }
    }
  };

  useEffect(() => {
    setOptions(selectedCustomer ? [selectedCustomer]: []);
  }, [selectedCustomer]);

  const props: SearchableDropDownProps<Customer> = {
    value: selectedCustomer,
    required: Boolean(required),
    handleSearch,
    onChange,
    options,
    label: 'Customer',
    debounce: 400,
    isOptionEqualToValue: (option, value) =>
      option.customer_id === value.customer_id,
    getOptionLabel: option =>
      `${option.code} - ${option.name} - ${option.shipping_address}`,
    onBlur: () => {
      setOptions(selectedCustomer ? [selectedCustomer]: []);
    },
    disabled,
  };

  return <SearchableDropDown  {...props}/>;
}
