import { DashBoardField } from './dashboard-field';
import { DashBoardListTag, DashBoardProps } from './reusables';

const DashBoardList: React.FC<DashBoardProps> = ({
  aggregatedDeliveryData,
  selectedField,
  onChange,
  children,
}) => {

  // To align the data in the expected order to avoid the dashboard view does not change
  const orderedData = {
    total: aggregatedDeliveryData.total ?? 0,
    received: aggregatedDeliveryData.received ?? 0,
    printed: aggregatedDeliveryData.printed ?? 0,
    shipped: aggregatedDeliveryData.shipped ?? 0,
    delivered: aggregatedDeliveryData.delivered ?? 0,
  };

  return (
    <DashBoardListTag>
      {children}
      {Object.entries(orderedData).map(([key, value]) => {
        return (
          <DashBoardField
            key={key}
            field={key as any}
            value={value}
            selected={selectedField === key}
            onClick={() => onChange(key)}
          />
        );
      })}
    </DashBoardListTag>
  );
};

export { DashBoardList };
