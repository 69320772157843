import { ErrorResponse } from 'models';
import { sendRequest, getRequest, postRequest, putRequest } from './gateway';
import { CustomerListResponse } from 'models/Customer';

function createCustomer({ code, name, shipping_address, phone, gst_number, lead_days }: CustomerListResponse) {
  const postPromise = postRequest<ErrorResponse>('/v1/customer', {
    code,
    name,
    shipping_address,
    phone,
    gst_number,
    lead_days,
  });
  return sendRequest(postPromise);
}
  
function updateCustomer({ customer_id, code, name, shipping_address, phone, gst_number, lead_days }: CustomerListResponse) {
  const putPromise = putRequest<ErrorResponse>(`/v1/customer/${customer_id}`, {
    code,
    name,
    shipping_address,
    phone,
    gst_number,
    lead_days,
    customer_id
  });
  return sendRequest(putPromise);
}

function getCustomerById({ customer_id }: { customer_id: string }): Promise<any> {
  const putPromise = getRequest<ErrorResponse>(`/v1/customer/${customer_id}}`);
  return sendRequest(putPromise);
}

export {
  createCustomer,
  updateCustomer,
  getCustomerById
};
