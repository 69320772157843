import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Button, Column, Form, Input, Table } from 'views/components/elements';
import { PackageAddIcon, PrintedFileIcon } from 'views/components/icon';
import { useNavigate, useLocation } from 'react-router-dom';
import './delivery-list.scss';
import { useWarehouseStore } from 'store/warehouse';
import { DeliveryListView, DeliveryItem, DeliveryAggregationDataKeys, DeliveryAggregationData } from 'models';
import {
  TableSortLabel,
  Stack,
  Tooltip,
} from '@mui/material';
import queryString from 'query-string';
import {
  Cached as ResetIcon,
  Replay as RefreshIcon,
  Search as SearchIcon,
  Download as DownloadIcon,
} from '@mui/icons-material';
import WarningCard from 'views/components/elements/WarningCard';
import { CSVLink } from 'react-csv';
import { fetchDeliveryReport, fetchTransactionDetail } from 'gateways/warehouse';
import { calculateDateRange, dateTimeFormat } from '../../shared/utils/string-utils';
import { generateCsvData } from 'views/shared/utils/csv-utils';
import CircularProgress from '@mui/material/CircularProgress';
import { DashBoardList } from './dashboard';
import { FlexRow } from 'views/components/styled';
import { DashBoardDatePickerField } from './dashboard/dashboard-date-picker';
import { CustomDateRangeOptions } from './dashboard/reusables';
import moment from 'moment';
import { handleError as handleApiError } from '../../shared/utils/error-utils';

const defaultStatusFilters = ['delivered', 'printed', 'received', 'shipped'];

interface DateFilter {
  label: CustomDateRangeOptions;
  from?: string | Date;
  to?: string | Date;
}

const DATE_FORMAT = 'YYYY-MM-DD';

const DeliveryList = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const csvLink = useRef(null);
  const {
    deliveryData: { deliveryList, deliveryPageFilter, hasNextPage, fetched },
    fetchDeliveries,
    sortDeliveries,
    resetData,
  } = useWarehouseStore();

  const [statusFilter, setStatusFilter] = useState<string[]>(defaultStatusFilters);
  const [sortConfig, setSortConfig] = useState<{
    field: string;
    direction: 'asc' | 'desc' | null;
  }>({ field: '', direction: null });
  const [query, setQuery] = useState<string>('');
  const [isResetDisabled, setIsResetDisabled] = useState(true);
  const [reportCsvData, setReportCsvData] = useState<DeliveryItem[]>([]);

  const [isErrorPopUpOpen, setIsErrorPopUpOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [DownloadReady, setDownloadReady] = useState(false);
  const isInitialFetchCompleted = useRef(false);
  const [loading, setLoading] = useState(false);
  const [isFetching, setIsFetching] = useState(false);

  const [dateFilter, setDateFilter] = useState<DateFilter>({
    label: 'view-all',
  });

  const [aggregatedDeliveryData, setAggregatedDeliveryData] = useState<DeliveryAggregationData>({
    total: 0,
    delivered: 0,
    shipped: 0,
    received: 0,
    printed: 0
  });

  const fetchTransaction = async (fromDate: string, toDate: string) => {
    const {failed, data} = handleApiError<DeliveryAggregationData[]>(await fetchTransactionDetail({
      toDate,
      fromDate,
    }));

    if (failed) {
      return handleError('Failed to retrieve status details. Please refresh.');
    }

    setAggregatedDeliveryData(data[0]);
  };

  useEffect(() => {
    if (!isInitialFetchCompleted.current) {
      const parsed = queryString.parse(location.search);
      const statuses =
        parsed.statuses !== undefined
          ? parsed.statuses.length === 0
            ? []
            : (parsed.statuses as string).split(',')
          : statusFilter;

      const queryParam = parsed.query ? String(parsed.query) : '';
      setStatusFilter(statuses);
      setQuery(queryParam);

      const label =
        (parsed.dateFilter as CustomDateRangeOptions) ?? dateFilter.label;

      const {from, to} = getFormattedDate({
        label,
        from: parsed.from as string,
        to: parsed.to as string
      });
      const filter = {
        label,
        from,
        to,
      };

      setDateFilter({...filter});

      if (parsed.statuses === undefined) {
        const defaultStatuses = statusFilter.join(',');
        const searchString = queryString.stringify({
          ...parsed,
          statuses: defaultStatuses,
          dateFilter: label,
          from,
          to,
        });
        navigate(`?${searchString}`, { replace: true });
      }

      setIsResetDisabled(queryParam?.length <= 0);
      fetchContent(statuses, 1, queryParam, filter);
      fetchTransaction(from, to);

      isInitialFetchCompleted.current = true;
    }

    return () => {
      resetData();
    };
  }, [location]);

  useEffect(() => {
    if (DownloadReady) {
      setTimeout(() => {
        if (csvLink.current) {
          csvLink.current.link.click();
          setDownloadReady(false);
        }
      }, 0);
    }
  }, [DownloadReady]);

  const goTo = useCallback((to: string) => {
    const fromQuery = dateFilter.from ? `&from=${dateFilter.from}` : '';
    const toQuery = dateFilter.to ? `&to=${dateFilter.to}` : '';
    const dateFilterQuery = `dateFilter=${dateFilter.label}${fromQuery}${toQuery}`;

    navigate(to, {
      state: {
        redirectToDeliveryListPage: `/delivery?query=${query}&statuses=${statusFilter.toString()}&${dateFilterQuery}`,
      }
    });
  }, [navigate, query, statusFilter]);

  const handleEditClick = useCallback((delivery_id: string) => {
    goTo(`/delivery/${delivery_id}/review`);
  }, [goTo]);
  const handleAddDelivery = () => {
    goTo('/delivery/new');
  };

  const handleStatusFilter = useCallback(
    (selectedItems) => {
      updateURL({ query, statuses: selectedItems, dateFilter: {...dateFilter} });
    },
    [query, dateFilter]
  );

  const handleSortChange = useCallback((field: string) => {
    const direction = sortConfig.field === field && sortConfig.direction === 'asc' ? 'desc' : 'asc';
    setSortConfig({ field, direction });
    sortDeliveries(field, direction);
  }, [sortConfig, sortDeliveries]);

  const updateURL = useCallback(({ query, statuses, dateFilter }: {query: string, statuses: string[], dateFilter: DateFilter}) => {
    const searchString = queryString.stringify({
      query: query,
      statuses: statuses.join(','),
      dateFilter: dateFilter.label,
      from: dateFilter.from,
      to: dateFilter.to,
    });

    isInitialFetchCompleted.current = false;
    navigate(`?${searchString}`, { replace: true });
  }, [navigate]);

  const filteredDeliveries = useMemo(() => {
    const lowercasedQuery = query.toLowerCase();
    return deliveryList
      .filter(delivery =>
        (delivery.customer_name + delivery.shipping_address + delivery.invoice_number)
          .toLowerCase()
          .includes(lowercasedQuery)
      )
      .filter(delivery => statusFilter.length === 0 || statusFilter.includes(delivery.status));
  }, [deliveryList, query, statusFilter]);

  const columns: Column<DeliveryListView>[] = [
    {
      field: 'customer_name',
      label: (
        <TableSortLabel
          active={sortConfig.field === 'customer_name'}
          direction={
            sortConfig.field === 'customer_name' ? sortConfig.direction : 'asc'
          }
          onClick={() => handleSortChange('customer_name')}
        >
          Customer
        </TableSortLabel>
      ),
      width: 120,
      autoResize: false,
    },
    {
      field: 'shipping_address',
      label: (
        <TableSortLabel
          active={sortConfig.field === 'shipping_address'}
          direction={
            sortConfig.field === 'shipping_address'
              ? sortConfig.direction
              : 'asc'
          }
          onClick={() => handleSortChange('shipping_address')}
        >
          Delivery Location
        </TableSortLabel>
      ),
      width: 150,
      autoResize: false
    },
    {
      field: 'invoice_number',
      label: (
        <TableSortLabel
          active={sortConfig.field === 'invoice_number'}
          direction={
            sortConfig.field === 'invoice_number' ? sortConfig.direction : 'asc'
          }
          onClick={() => handleSortChange('invoice_number')}
        >
          Invoice No.
        </TableSortLabel>
      ),
      width: 100,
      valueGetter: ({ invoice_number }) => (
        <Tooltip title={invoice_number} placement='bottom-start'>
          <span>{invoice_number}</span>
        </Tooltip>
      ),
      autoResize: false,
    },
    {
      field: 'total_cases',
      label: (
        <TableSortLabel
          active={sortConfig.field === 'total_cases'}
          direction={
            sortConfig.field === 'total_cases' ? sortConfig.direction : 'asc'
          }
          onClick={() => handleSortChange('total_cases')}
        >
          No.of Cases
        </TableSortLabel>
      ),
      width: 120,
    },
    {
      field: 'vehicle_name',
      label: (
        <TableSortLabel
          active={sortConfig.field === 'vehicle_name'}
          direction={
            sortConfig.field === 'vehicle_name' ? sortConfig.direction : 'asc'
          }
          onClick={() => handleSortChange('vehicle_name')}
        >
          Vehicle
        </TableSortLabel>
      ),
      width: 150,
      autoResize: false
    },
    {
      field: 'created_date',
      label: (
        <TableSortLabel
          active={sortConfig.field === 'created_date'}
          direction={
            sortConfig.field === 'created_date' ? sortConfig.direction : 'asc'
          }
          onClick={() => handleSortChange('created_date')}
        >
          Created Date
        </TableSortLabel>
      ),
      width: 120,
      format: 'date',
    },
    {
      field: 'status',
      label: 'Status',
      width: 120,
      valueGetter: row => (
        <span className={`status ${row.status.toLowerCase()}`}>
          {row.status}
        </span>
      ),
    },
    {
      field: 'view',
      label: '',
      width: 120,
      valueGetter: (row: DeliveryListView) => (
        <Button
          variant='text'
          style={{ fontWeight: 'normal', fontSize: '12px' }}
          onClick={() => handleEditClick(row.delivery_id)}
          endIcon={(['printed', 'shipped', 'delivered'].includes(row.status) && !row.pod_image_link) ? (
            <PrintedFileIcon />
          ) : null
          }
        >
          View
        </Button>
      ),
    },
  ];

  const getFormattedDate = ({from, to, label}: DateFilter): {from: string, to: string} => {
    let formattedFromDate = moment(from).format(DATE_FORMAT);
    let formattedToDate = moment(to).format(DATE_FORMAT);

    if (label !== 'custom-date' && label !== 'view-all') {
      const temp = calculateDateRange(label);
      formattedFromDate = temp.from.format(DATE_FORMAT);
      formattedToDate = temp.to.format(DATE_FORMAT);
    }

    if (label === 'view-all') {
      formattedFromDate = undefined;
      formattedToDate = undefined;
    }

    return {from: formattedFromDate, to: formattedToDate};
  };

  const fetchContent = useCallback(async (statuses = defaultStatusFilters, page = 1, query = '', dateBasedFilter) => {
    setLoading(true);
    setIsFetching(true);
    const {from, to} = getFormattedDate(dateBasedFilter);

    const fltr = Object.assign({}, deliveryPageFilter, {
      query,
      statuses,
      page,
      fromDate: from,
      toDate: to,
    });
    const error = await fetchDeliveries(fltr);
    if (error?.failed) {
      handleError('Failed to fetch the deliveries. Please do refresh.');
    }
    setLoading(false);
    setIsFetching(true); 
  }, [deliveryPageFilter, fetchDeliveries]);

  const fetchReportData = useCallback(async (statuses = defaultStatusFilters, { from, to }: DateFilter,  query = '') => {
    try {
      const fromDate = from as string;
      const toDate = to as string;

      const deliveryData = await fetchDeliveryReport({ query, statuses, fromDate, toDate });
      if (Array.isArray(deliveryData) && deliveryData.length > 0) {
        setReportCsvData(deliveryData);
        setDownloadReady(true);
      } else {
        handleError('No data available to export.');
      }
    } catch (error) {
      handleError('Failed to fetch the delivery report. Please do refresh.');
    }
  }, []);

  const handleExport = useCallback(() => {
    fetchReportData(statusFilter, dateFilter, query);
  }, [statusFilter, query, dateFilter]);

  const handleSubmit = useCallback(({ query }) => {
    updateURL({ query, statuses: statusFilter, dateFilter });
  }, [statusFilter, updateURL, dateFilter]);

  const handleNextPage = useCallback(() => {
    fetchContent(statusFilter, deliveryPageFilter.page + 1, query, dateFilter);
  }, [statusFilter, deliveryPageFilter.page, query, dateFilter]);

  const handleReset = useCallback(() => {
    updateURL({ query: '', statuses: statusFilter, dateFilter });
  }, [statusFilter, updateURL, dateFilter]);

  const handleRefresh = useCallback(() => {
    updateURL({ query: '', statuses: defaultStatusFilters, dateFilter: {label: 'view-all'} });
  }, [defaultStatusFilters, updateURL]);

  const csvData = generateCsvData(reportCsvData);
  const filename = `Deliveries_${dateTimeFormat(new Date(), 'timestamp')}.csv`;

  const handleError = (error: string) => {
    setErrorMessage(error);
    setIsErrorPopUpOpen(true);
  };

  const handleStatusChange = (key: DeliveryAggregationDataKeys) => {
    const selectedItems = key === 'total' ? defaultStatusFilters : [key];
    handleStatusFilter(selectedItems);
  };

  const handleDateFilterChange = (label: CustomDateRangeOptions, fromDate: Date, toDate: Date) => {
    const {from, to } = getFormattedDate({label, from: fromDate, to: toDate});
    updateURL({query, statuses: statusFilter, dateFilter: {label, from, to}});
  };

  const selectedField = (statusFilter.length > 1 ? 'total' : statusFilter[0]) as DeliveryAggregationDataKeys;

  return (
    <div className='custom-table-wrapper'>
      <WarningCard
        open={isErrorPopUpOpen}
        onClose={() => setIsErrorPopUpOpen(false)}
        message={errorMessage}
        severity='error'
        durationInSeconds={10}
      />
      <div className='head' style={{marginBottom: '3rem'}}>
        <FlexRow style={{gap: '1rem'}}>
          <h1>Deliveries</h1>
          {/* The data is hardcoded. Will replace once the endpoint is ready. */}
          <DashBoardList aggregatedDeliveryData={aggregatedDeliveryData} onChange={handleStatusChange} selectedField={selectedField}>
            <DashBoardDatePickerField label={dateFilter.label} from={dateFilter.from as Date} to={dateFilter.to as Date} onChange={handleDateFilterChange}/>
          </DashBoardList>
        </FlexRow>
      </div>
      <div className='row action'>
        <Form
          initialValues={{ query }}
          onSubmit={handleSubmit}
          enableReinitialize={true}
        >
          <Stack direction='row' spacing={2} height='40px'>
            <Input
              name='query'
              placeholder='Search Deliveries'
            />
            <Button
              variant='contained'
              type='submit' sx={{minWidth: '48px'}}><SearchIcon /></Button>
            <Button
              variant='text'
              startIcon={
                <span id='package-icon'>
                  <RefreshIcon />
                </span>
              }
              onClick={handleReset}
              disabled={isResetDisabled}
            >
              Reset
            </Button>
            <Button
              variant='text'
              startIcon={
                <span id='package-icon'>
                  <ResetIcon />
                </span>
              }
              onClick={handleRefresh}
            >
              Refresh
            </Button>
            <Button
              variant='text'
              startIcon={<DownloadIcon />}
              onClick={handleExport}
            >
              Export
            </Button>
            <CSVLink
              data={csvData}
              ref={csvLink}
              asyncOnClick={false}
              filename={filename}
              className='export-btn'
            />
          </Stack>
        </Form>
        <div>
          <Button
            variant='contained'
            startIcon={
              <span id='package-icon'>
                <PackageAddIcon />
              </span>
            }
            onClick={handleAddDelivery}
            style={{height: '40px'}}
          >
            Add Delivery
          </Button>
        </div>
      </div>

      {loading && (
        <div className='spinner' >
          <CircularProgress />
        </div>
      )}
      {(
        <Table
          columns={columns}
          rows={filteredDeliveries}
          stickyHeader
          onSortChange={handleSortChange}
          hasMoreRows={hasNextPage}
          infiniteScroll
          onNextPage={handleNextPage}
        />
      )}
      {!loading && fetched && filteredDeliveries.length <= 0 && !isFetching && (
        <div>No records found.</div>
      )}
    </div>
  );
};

export default DeliveryList;
