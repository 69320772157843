import { ReactNode } from 'react';
import { DeliveredIcon, DeliveryIcon, PrintedIcon, ReceivedIcon, ShippedIcon } from 'views/components/icon';
import { DeliveryAggregationData } from 'models';
import { DashBoardContentTag, DashBoardFieldProps, DashBoardFieldTag, DashBoardIconHolderTag } from './reusables';

const getDashboardConfig = (
  key: keyof DeliveryAggregationData
): {
  label: string;
  icon?: ReactNode;
} => {
  switch (key) {
  case 'total':
    return {
      icon: (
        <DashBoardIconHolderTag sx={{ backgroundColor: '#E7ECF3' }}>
          <DeliveryIcon color='#3F6CAB' />
        </DashBoardIconHolderTag>
      ),
      label: 'All Deliveries',
    };
  case 'received':
    return {
      icon: (
        <DashBoardIconHolderTag sx={{ backgroundColor: '#EBEBEB' }}>
          <ReceivedIcon />
        </DashBoardIconHolderTag>
      ),
      label: 'Received',
    };
  case 'delivered':
    return {
      icon: (
        <DashBoardIconHolderTag sx={{ backgroundColor: '#C5FFCA' }}>
          <DeliveredIcon />
        </DashBoardIconHolderTag>
      ),
      label: 'Delivered',
    };
  case 'printed':
    return {
      icon: (
        <DashBoardIconHolderTag sx={{ backgroundColor: '#FFEFE5' }}>
          <PrintedIcon />
        </DashBoardIconHolderTag>
      ),
      label: 'Printed',
    };
  case 'shipped':
    return {
      icon: (
        <DashBoardIconHolderTag sx={{ backgroundColor: '#FFF4DE'}}>
          <ShippedIcon />
        </DashBoardIconHolderTag>
      ),
      label: 'In Transit',
    };
  }
};

const DashBoardField: React.FC<DashBoardFieldProps> = ({
  field,
  value,
  onClick,
  selected = false,
}) => {
  const { icon, label } = getDashboardConfig(field) ?? {};
  return (
    <DashBoardFieldTag onClick={onClick} className={selected ? 'selected': null}>
      {icon}
      <DashBoardContentTag>
        <span className='label'>{label}</span>
        <strong className='value'>{value}</strong>
      </DashBoardContentTag>
    </DashBoardFieldTag>
  );
};

export { DashBoardField };
