import { Button, Input, Textarea } from 'views/components/elements';
import {
  FormField,
  Required,
  Actions,
  Content,
  PopUpForm,
} from 'views/pages/delivery/StyledComponent';
import {
  palletValidationSchema,
  removeExtraSpace,
} from 'views/shared/utils/form-validator';
import { PalletFormData } from 'models/Form';
import { Pallet } from 'models';
import './index.scss';
import '../../delivery/forms/UserForm.scss';
import { ErrorMessage, Field } from 'formik';
import { createPallet, updatePallet } from 'gateways/warehouse';
import { handleError } from 'views/shared/utils/error-utils';

const initialValues: PalletFormData = {
  pallet_code: '',
  notes: '',
  is_empty: false,
};

interface PalletFormProps {
  edit: boolean;
  value: Pallet;
  onSubmit: (failed: boolean, error: string, data: Pallet) => void;
  onClose: () => void;
}

const PalletForm: React.FC<PalletFormProps> = ({
  edit,
  value,
  onSubmit,
  onClose,
}: PalletFormProps) => {
  const handleSubmit = async ({
    pallet_code,
    notes,
    is_empty,
  }: PalletFormData) => {
    const palletData = {
      ...value,
      pallet_code: removeExtraSpace(pallet_code),
      notes: removeExtraSpace(notes),
      is_empty: Boolean(is_empty),
    } as Pallet;

    const response = await (edit ? updatePallet : createPallet)(
      palletData
    );
    const { failed, error, data } = handleError<Pallet>(response);

    onSubmit(failed, error, data);
  };

  return (
    <PopUpForm
      className='user-form form'
      initialValues={edit ? value : initialValues}
      validationSchema={palletValidationSchema}
      validateOnChange={true}
      validateOnBlur={true}
      onSubmit={handleSubmit}
      onReset={onClose}
    >
      {({ errors, setFieldTouched, touched, setFieldValue }) => (
        <Content>
          <FormField className='form-group'>
            <label>
              Pallet Code <Required>*</Required>
            </label>
            <Input
              name='pallet_code'
              placeholder='Pallet Code'
              className={
                touched.pallet_code && errors.pallet_code
                  ? 'error-input'
                  : ''
              }
              disabled={edit}
              onChange={event => {
                const { name, value } = event.target;
                setFieldTouched(name, true);
                setFieldValue(name, value);
              }}
            />
            <ErrorMessage
              name='pallet_code'
              component='span'
              className='error'
            />
          </FormField>
          <FormField className='form-group'>
            <label>Notes</label>
            <Textarea
              name='notes'
              placeholder='Notes'
              rows={4}
              className={touched.notes && errors.notes ? 'error-input' : ''}
              onChange={event => {
                const { name, value } = event.currentTarget;
                setFieldTouched(name, true);
                setFieldValue(name, value);
              }}
            />
            <ErrorMessage name='notes' component='span' className='error' />
          </FormField>
          <FormField className='form-group'>
            <label><Field name='is_empty' label='Is Empty' type='checkbox'/> Is Empty</label>
          </FormField>
          <Actions className='btn-container flex-justify-end'>
            <Button type='submit'>{edit ? 'Update' : 'Create'}</Button>
            <Button
              variant='text'
              color='secondary'
              className='cancel-btn'
              onClick={onClose}
            >
              Discard
            </Button>
          </Actions>
        </Content>
      )}
    </PopUpForm>
  );
};

export { PalletForm };
