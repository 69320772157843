import styled from '@emotion/styled';
import { Button } from '@mui/material';
import { useState } from 'react';
import { DateRange, DayPicker } from 'react-day-picker';
import 'react-day-picker/style.css';
import { Actions } from 'views/pages/delivery/StyledComponent';
import './CustomDateRangePicker.scss';

const StyledSpan = styled('span')({
  fontSize: '0.85rem',
  color: '#494E50',
  marginRight: '1rem',
});

const StyledButton = styled(Button)({
  textTransform: 'capitalize',
  lineHeight: 1.5,
  width: 'fit-content !important',
});

interface CustomDateRangePickerProps {
  from: Date;
  to: Date;
  onCancel?: () => void;
  onApply?: (dateRange: DateRange) => void;
  dateFormatter: (date: Date) => string;
}

const CustomDateRangePicker: React.FC<CustomDateRangePickerProps> = ({
  from,
  to,
  onApply,
  onCancel,
  dateFormatter,
}) => {
  const [range, setRange] = useState<DateRange>({
    from,
    to,
  });

  const handleOnApply = () => {
    if (!(range?.from && range?.to)) {
      return;
    }

    onApply(range);
  };

  const displayFromDate = dateFormatter(range?.from);
  const displayToDate = dateFormatter(range?.to);
  return (
    <DayPicker
      mode='range'
      numberOfMonths={2}
      timeZone="+05:30"
      selected={range}
      onSelect={setRange}
      components={{
        Weekday(props) {
          const customWeekday = props.children.toString().substring(0,1);
          return <th {...props}>{customWeekday}</th>;
        },
      }}
      footer={
        <Actions
          className='btn-container flex-justify-end'
          style={{ alignItems: 'center', gap: '0.5rem' }}
        >
          <StyledSpan>{`${displayFromDate} - ${displayToDate}`}</StyledSpan>
          <StyledButton onClick={onCancel} variant='text' color='secondary' className='cancel-btn'>
            Cancel
          </StyledButton>
          <StyledButton onClick={handleOnApply} variant='contained'>
            Apply
          </StyledButton>
        </Actions>
      }
    />
  );
};

export { CustomDateRangePicker };
