import { Stack, Typography, Box } from '@mui/material';
import { EditPenIcon, LocationIcon, SettingsIcon, ZoneIcon } from 'views/components/icon';
import './warehouses.scss';
import { Button, Table } from 'views/components/elements';
import { WarehouseTab } from './components/WarehouseTab';
import { WarehouseIcon } from 'views/components/icon';
import { useWarehouseStore } from 'store/warehouse';
import HeaderWithAction from 'views/components/elements/HeaderWithAction';
import { useEffect, useRef, useState } from 'react';
import { Location, Warehouse, Zone } from 'models';
import WarningCard from 'views/components/elements/WarningCard';
import WarehouseForm from 'views/pages/settings/forms/WarehouseForm';
import PopUpDialog from 'views/components/elements/PopUpDialog';
import CustomAccordion from 'views/components/elements/CustomAccordian';
import { BodyText, TitleText } from '../delivery/StyledComponent';
import IOSSwitch from 'views/components/elements/CustomSwitch';
import { fetchZones, updateZoneStatus, fetchLocations, updateLocationStatus } from 'gateways/warehouse';
import { handleError } from 'views/shared/utils/error-utils';
import { EntityResponse } from 'views/shared/utils/delivery-utils';
import { ZoneForm } from 'views/pages/settings/forms';
import LocationForm from './forms/LocationForm';
import { PalletList } from './pallets/pallet-list';
import { FlexRow } from 'views/components/styled';
import ConfirmDialog from 'views/components/elements/ConfirmDialog';

type PopUpFormTypes = 'warehouse' | 'zone' | 'location' | 'pallet_window';
type PopUpState = 'open' | 'close';
type ConfirmationDialogType = 'zone' | 'location';
type DialogConfigOptions = {
  isOpen?: boolean;
  message?: string;
  type?: ConfirmationDialogType;
  data?: Partial<Zone & Location>;
  warehouse?: boolean;
  zone?: boolean;
  location?: boolean;
  pallet_window?: boolean; 
  selectedForm?: PopUpFormTypes;
  isEditForm?: boolean;
  title?: string;
}
const defaultDialogConfig: DialogConfigOptions = {
  isOpen: false,
  message: '',
  data: {},
};

interface LocationNotesProps {
  notes: string;
  switchChecked: boolean;
  onSwitchChange: (enable: boolean) => void;
  onEditClick: () => void;
}

const Warehouses: React.FC = () => {
  const { selectedWarehouse, fetchWarehouses, resetData } = useWarehouseStore();
  const [isDataFetched, setIsDataFetched] = useState(false);
  const isInitialFetchCompleted = useRef(false);
  const [zones, setZones] = useState<Zone[]>([]);
  const [isZoneDataFetched, setIsZoneDataFetched] = useState(false);
  const [selectedZone, setSelectedZone] = useState<Zone>();
  const [locations, setLocations] = useState<Location[]>([]);
  const [expandedZoneId, setExpandedZoneId] = useState<number | null>(null);
  const [selectedLocation, setSelectedLocation] = useState<Location>();

  useEffect(() => {
    if (!isInitialFetchCompleted.current) {
      fetchWarehouses().then(() => setIsDataFetched(true));
      isInitialFetchCompleted.current = true;
    }

    return () => resetData();
  }, []);

  const fetchZoneDetails = async (warehouse_id: number): Promise<EntityResponse<Zone[]>> => {
    const response = await fetchZones(warehouse_id);
    return handleError<Zone[]>(response);
  };

  const updateZone = async (zone: Partial<Zone>): Promise<EntityResponse<Zone>> => {
    const response = await updateZoneStatus(zone as Zone);
    return handleError<Zone>(response);
  };

  const fetchLocationDetails = async (warehouse_id: number, zone_id?: number): Promise<EntityResponse<{ collection: Location[] }>> => {
    const response = await fetchLocations(warehouse_id, zone_id); 
    return handleError(response);
  };

  useEffect(() => {
    const fetchData = async () => {
      if (selectedWarehouse?.warehouse_id) {
        const { failed, data } = await fetchZoneDetails(selectedWarehouse.warehouse_id);
        setIsZoneDataFetched(true);

        if (!failed) {
          setZones(data);
        }
      }
    };

    fetchData();

    return () => {
      setZones([]);
      setIsZoneDataFetched(false);
    };
  }, [selectedWarehouse]);

  const [isErrorPopUpOpen, setIsErrorPopUpOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [popUpDialogConfig, setPopUpDialogConfig] = useState<DialogConfigOptions>(defaultDialogConfig);

  const handlePopUp =
    (popUp: PopUpFormTypes, type: PopUpState, isEdit?: boolean) => () => {
      const title = popUp === 'pallet_window' ?
        `Pallet Settings - ${selectedWarehouse.warehouse_code}, ${selectedWarehouse.warehouse_name}` :
        `${isEdit ? 'Edit' : 'Create'} ${popUp}`;

      setPopUpDialogConfig({
        isEditForm: Boolean(isEdit),
        [popUp]: type === 'open',
        selectedForm: popUp,
        title,
      });
    };

  // Currently the edit zone functionality is inside the accoridion. This is to handle form in accordion.
  const handleZoneFormSubmit = (zone: Zone) => {
    if (popUpDialogConfig.isEditForm) {
      return setZones((prev) => prev.map(function (zn) {
        return this.zone_id === zn.zone_id ? this : zn;
      }, {...zone})
      );
    }

    return setZones((prev) => [...prev, zone]);
  };

  // Currently the edit location functionality is inside the accoridion. This is to handle form in accordion.
  const handleLocationFormSubmit = (location: Location) => {
    if (popUpDialogConfig.isEditForm) {
      return setLocations((prev) => prev.map(function (lcn) {
        return this.location_id === lcn.location_id ? this : lcn;
      }, {...location})
      );
    }

    return setLocations((prev) => [...prev, {...location}]);
  };

  const handleFormSubmit =
    (formType: PopUpFormTypes, callback?: any) => (failed: boolean, error: string, data?: unknown) => {
      if (failed) {
        return handleAlert(error);
      }

      if (callback) {
        callback(data);
      }

      handlePopUp(formType, 'close', false)();
    };

  const handleAlert = (message: string) => {
    setErrorMessage(message);
    setIsErrorPopUpOpen(true);
  };

  const columns = [
    {
      field: 'location_code',
      label: 'Location Code',
      width: 120,
    },
    {
      field: 'location_name',
      label: 'Location Name',
      width: 120,
    },
    {
      field: 'notes',
      label: 'Notes',
      width: 200,
      valueGetter: ({notes, is_active, location_id, zone_id, location_code, location_name, warehouse_id}: Location) => (
        <LocationNotes
          notes={notes}
          switchChecked={is_active}
          onSwitchChange={handleLocationToggle(zone_id, location_id)}
          onEditClick={() => {
            setSelectedLocation({ notes, is_active, location_id, zone_id, location_code, location_name, warehouse_id });
            handlePopUp('location', 'open', true)();
          }}
        />
      ),
    },
  ];

  const rows = [...locations];

  const LocationNotes: React.FC<LocationNotesProps> = ({
    notes,
    switchChecked,
    onSwitchChange,
    onEditClick
  }) => {
    const handleSwitchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      event.stopPropagation();
      const isChecked = event.target.checked;
      onSwitchChange(isChecked);
    };

    return  <Box display='flex' alignItems='center'>
      <Typography style={{ fontWeight: 'normal', fontSize: '12px', flex: 1 }}>
        {notes}
      </Typography>
      <Box display='flex' alignItems='center' marginLeft='auto'>
        <IOSSwitch
          defaultChecked={switchChecked}
          onChange={handleSwitchChange}
          size='small'
          sx={{ transform: 'scale(0.75)' }}
        />
        <Button
          variant='text'
          style={{ fontWeight: 'normal', fontSize: '12px' }}
          endIcon={<EditPenIcon />}
          onClick={onEditClick}
        />
      </Box>
    </Box>;
  };

  const handleZoneUpdate = async ({warehouse_id, zone_id, is_active}: Partial<Zone>) => {
    const { failed, error } = await updateZone({warehouse_id, zone_id, is_active});

    if (failed) {
      return handleAlert(`Failed to ${ is_active? 'enable' : 'disable' } the zone. ${error}`);
    }

    setZones((zones) => {
      return zones.map(function zoneMapper(zone) {
        const isActive = this.zone_id === zone.zone_id ? this.is_active: zone.is_active;
        return {...zone, is_active: isActive};
      }, { zone_id, is_active });
    });
  };

  const enableOrDisableLocation = async (location_id: number, is_active: boolean): Promise<EntityResponse<Location>> => {
    const response = await updateLocationStatus(location_id, is_active);
    return handleError<Location>(response);
  };

  const handleLocationUpdate = async (
    zone_id: number,
    location_id: number,
    is_active: boolean
  ) => {
    const { failed, error } = await enableOrDisableLocation(
      location_id,
      is_active
    );

    if (failed) {
      return handleAlert(
        `Failed to ${is_active ? 'enable' : 'disable'} the location. ${error}`
      );
    }

    const activeLocations = locations.filter(function locationFilter({location_id, is_active}) {
      return location_id === this.location_id ? this.is_active : is_active;
    }, {location_id, is_active});

    const has_active_locations = activeLocations.length;

    setLocations(locations => {
      return locations.map(
        function locationMapper(location) {
          const isActive =
            this.location_id === location.location_id
              ? this.is_active
              : location.is_active;
          return { ...location, is_active: isActive };
        },
        { location_id, is_active }
      );
    });

    setZones(zones => {
      return zones.map(
        function zoneMapper(zone) {
          const has_active_locations =
            zone.zone_id === this.zone_id
              ? this.has_active_locations
              : zone.has_active_locations;
          return { ...zone, has_active_locations };
        },
        { zone_id, has_active_locations }
      );
    });
  };

  const handleLocationToggle = (zone_id: number, location_id: number) => async (enable: boolean) => {
    setPopUpDialogConfig({
      type: 'location',
      message: `Are you sure you want to ${enable? 'enable': 'disable'} this location?`,
      isOpen: true,
      data: {
        is_active: enable,
        location_id,
        zone_id,
      }
    });
  };

  const onClickConfirm = (type: ConfirmationDialogType, data: Partial<Zone & Location>) => async () => {
    const { warehouse_id, zone_id, is_active, location_id } = data;

    switch(type) {
    case 'zone':
      await handleZoneUpdate({ warehouse_id, zone_id, is_active });
      break;
    case 'location':
      await handleLocationUpdate(zone_id, location_id, is_active);
      break;
    }

    onClickCancel();
  };

  const onClickCancel = () => setPopUpDialogConfig({...defaultDialogConfig});

  const handleZoneToggle = ({ warehouse_id, zone_id, has_active_locations }: Partial<Zone>) => async (enable: boolean) => {
    if (!enable && has_active_locations) {
      return setPopUpDialogConfig({
        type: 'zone',
        message: 'There are active locations in this zone. If disabled, you cannot move the pallets in this zone. Do you still want to disable?',
        isOpen: true,
        data: {
          warehouse_id,
          zone_id,
          is_active: enable,
          has_active_locations
        }
      });
    }

    setPopUpDialogConfig({
      type: 'zone',
      message: `Are you sure you want to ${enable? 'enable' : 'disable'} this zone?`,
      isOpen: true,
      data: {
        warehouse_id,
        zone_id,
        is_active: enable,
        has_active_locations
      }
    });
  };

  const AccordionSection: React.FC<{
    zones: Zone[];
    rows: any[];
    columns: any[];
  }> = ({ zones, rows, columns }) => (
    <Box
      sx={{
        maxHeight: '300px',
        overflowY: 'auto', 
      }}
    >
      {zones.map(({zone_id, zone_code, zone_name, notes, is_active, warehouse_id, has_active_locations}) => (
        <CustomAccordion key={zone_id} title={`Zone-${zone_code}`} switchChecked={is_active} onEditClick={() => {
          setSelectedZone({ zone_id, zone_code, zone_name, notes, warehouse_id});
          handlePopUp('zone', 'open', true)();
        }}
        isExpanded={expandedZoneId === zone_id} 
        onAccordionExpand={async () => {
          if (expandedZoneId !== zone_id) {
            setExpandedZoneId(zone_id);
            const { failed, data, error } = await fetchLocationDetails(warehouse_id, zone_id);
            if (failed) {
              handleAlert(error || 'Failed to fetch location details.');
            } else {
              setLocations(data.collection);
            }
          }
        }}
        onSwitchChange={handleZoneToggle({warehouse_id, zone_id, has_active_locations})}>
          <BodyText>{zone_name}</BodyText>
          <BodyText>{notes}</BodyText>
          <Box
            style={{ display: 'flex', alignItems: 'center', marginTop: '24px' }}
          >
            <TitleText>Location</TitleText>
            <Button
              variant='contained'
              startIcon={<LocationIcon />}
              style={{ height: '40px', marginLeft: 'auto' }}
              onClick={() => {
                setSelectedLocation({warehouse_id, zone_id} as unknown as Location);
                handlePopUp('location', 'open')();
              }}
            >
              Add Location
            </Button>
          </Box>
          <Box className='location-table-container' sx={{ marginTop: '16px' }}>
            {rows.length === 0 ? (
              <Typography variant="subtitle2" sx={{ textAlign: 'center', marginTop: '16px' }}>
                 No Location Data Found
              </Typography>
            ) : (
              <Table rows={rows} columns={columns} infiniteScroll />
            )}
          </Box>
        </CustomAccordion>
      ))}
    </Box>
  );

  return (
    <>
      <WarningCard
        open={isErrorPopUpOpen}
        onClose={() => setIsErrorPopUpOpen(false)}
        message={errorMessage}
        severity='error'
        durationInSeconds={5}
      />
      <Stack spacing={5} className='settings-container'>
        <WarehouseTab>
          <Button
            variant='contained'
            color='primary'
            startIcon={<WarehouseIcon />}
            className='height-40px'
            onClick={handlePopUp('warehouse', 'open', false)}
          >
            Add Warehouse
          </Button>
        </WarehouseTab>
        {isDataFetched && selectedWarehouse?.warehouse_id && (
          <Stack spacing={5}>
            <Stack
              direction='row'
              justifyContent='space-between'
              alignItems='center'
            >
              <HeaderWithAction
                headerFontSize='32px'
                showIcon={true}
                content={`Warehouse-${selectedWarehouse.warehouse_code}`}
                onIconClick={handlePopUp('warehouse', 'open', true)}
              />
              <FlexRow>
                <Button
                  variant='text'
                  color='primary'
                  startIcon={<SettingsIcon color='#1D4ED8'/>}
                  className='height-40px'
                  onClick={handlePopUp('pallet_window', 'open')}
                >
                  Pallet Settings
                </Button>
                <Button
                  variant='contained'
                  color='primary'
                  startIcon={<ZoneIcon />}
                  className='height-40px'
                  onClick={handlePopUp('zone', 'open', false)}
                >
                  Add Zone
                </Button>
              </FlexRow>
            </Stack>
          </Stack>
        )}
        {isDataFetched && !selectedWarehouse?.warehouse_id && (
          <p>No Warehouse data found</p>
        )}
        {isDataFetched && selectedWarehouse?.warehouse_id && (
          <p>{selectedWarehouse.warehouse_name}</p>
        )}
        <PopUpDialog
          open={Boolean(popUpDialogConfig[popUpDialogConfig.selectedForm])}
          onClose={handlePopUp(popUpDialogConfig.selectedForm, 'close')}
          title={popUpDialogConfig.title}
          sx={{alignSelf: 'baseline', maxWidth: popUpDialogConfig.pallet_window ? '80%': undefined}}
        >
          { popUpDialogConfig.warehouse && <WarehouseForm
            value={
              popUpDialogConfig.isEditForm ? selectedWarehouse : ({} as unknown as Warehouse)
            }
            onSubmit={handleFormSubmit('warehouse')}
            onClose={handlePopUp('warehouse', 'close')}
            edit={popUpDialogConfig.isEditForm}
          /> }
          { popUpDialogConfig.zone && <ZoneForm
            value={
              popUpDialogConfig.isEditForm ? selectedZone : ({warehouse_id: selectedWarehouse.warehouse_id} as unknown as Zone)
            }
            onSubmit={handleFormSubmit('zone', handleZoneFormSubmit)}
            onClose={handlePopUp('zone', 'close')}
            edit={popUpDialogConfig.isEditForm}
          /> }
          { popUpDialogConfig.pallet_window && <PalletList
            warehouse_id={selectedWarehouse?.warehouse_id}
            onError={(error) => {
              setErrorMessage(error);
              setIsErrorPopUpOpen(true);
            }}
            onDiscard={handlePopUp('pallet_window', 'close')}
          /> }
          { popUpDialogConfig.location && <LocationForm
            value={selectedLocation}
            onSubmit={handleFormSubmit('location', handleLocationFormSubmit)}
            onClose={handlePopUp('location', 'close')}
            edit={popUpDialogConfig.isEditForm}
          /> }
        </PopUpDialog>
        { isZoneDataFetched && zones.length > 0 && <AccordionSection
          zones={zones}
          rows={rows}
          columns={columns}
        /> }
        { isZoneDataFetched && zones.length === 0 && <p>No Zone data found</p> }
      </Stack>
      <ConfirmDialog
        isOpen={popUpDialogConfig.isOpen}
        confirmationMsg={popUpDialogConfig.message}
        onClickConfirm={() =>
          onClickConfirm(popUpDialogConfig.type, popUpDialogConfig.data)()
        }
        onClose={onClickCancel}
      />
    </>
  );
};

export default Warehouses;
