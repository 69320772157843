import { Button, Input, Textarea } from 'views/components/elements';
import {
  FormField,
  Required,
  Actions,
  Content,
  PopUpForm,
} from 'views/pages/delivery/StyledComponent';
import {
  removeExtraSpace,
  ZoneValidationSchema,
} from 'views/shared/utils/form-validator';
import { ZoneFormData } from 'models/Form';
import { Zone } from 'models';
import './index.scss';
import '../../delivery/forms/UserForm.scss';
import { ErrorMessage } from 'formik';
import { createZone, updateZone } from 'gateways/warehouse';
import { handleError } from 'views/shared/utils/error-utils';

const initialValues: ZoneFormData = {
  zone_code: '',
  zone_name: '',
  notes: '',
};

interface ZoneFormProps {
  edit: boolean;
  value: Zone;
  onSubmit: (failed: boolean, error: string, data: Zone) => void;
  onClose: () => void;
}

const ZoneForm: React.FC<ZoneFormProps> = ({
  edit,
  value,
  onSubmit,
  onClose,
}: ZoneFormProps) => {
  const handleSubmit = async ({
    zone_code,
    zone_name,
    notes,
  }: ZoneFormData) => {
    const zoneData = {
      ...value,
      zone_code: removeExtraSpace(zone_code),
      zone_name: removeExtraSpace(zone_name),
      notes: removeExtraSpace(notes),
    } as Zone;

    const response = await (edit ? updateZone : createZone)(
      zoneData
    );
    const { failed, error, data } = handleError<Zone>(response);

    onSubmit(failed, error, data);
  };

  return (
    <PopUpForm
      className='user-form form'
      initialValues={edit ? value : initialValues}
      validationSchema={ZoneValidationSchema}
      validateOnChange={true}
      validateOnBlur={true}
      onSubmit={handleSubmit}
      onReset={onClose}
    >
      {({ errors, setFieldTouched, touched, setFieldValue }) => (
        <Content>
          <FormField className='form-group'>
            <label>
              Zone Code <Required>*</Required>
            </label>
            <Input
              name='zone_code'
              placeholder='Zone Code'
              className={
                touched.zone_code && errors.zone_code
                  ? 'error-input'
                  : ''
              }
              disabled={edit}
              onChange={event => {
                const { name, value } = event.target;
                setFieldTouched(name, true);
                setFieldValue(name, value);
              }}
            />
            <ErrorMessage
              name='zone_code'
              component='span'
              className='error'
            />
          </FormField>
          <FormField className='form-group'>
            <label>
              Zone Name <Required>*</Required>
            </label>
            <Input
              name='zone_name'
              placeholder='Zone Name'
              className={
                touched.zone_name && errors.zone_name
                  ? 'error-input'
                  : ''
              }
              onChange={event => {
                const { name, value } = event.target;
                setFieldTouched(name, true);
                setFieldValue(name, value);
              }}
            />
            <ErrorMessage
              name='zone_name'
              component='span'
              className='error'
            />
          </FormField>
          <FormField className='form-group'>
            <label>Notes</label>
            <Textarea
              name='notes'
              placeholder='Notes'
              className={touched.notes && errors.notes ? 'error-input' : ''}
              onChange={event => {
                const { name, value } = event.currentTarget;
                setFieldTouched(name, true);
                setFieldValue(name, value);
              }}
            />
            <ErrorMessage name='notes' component='span' className='error' />
          </FormField>
          <Actions className='btn-container flex-justify-end'>
            <Button type='submit'>{edit ? 'Update' : 'Create'}</Button>
            <Button
              variant='text'
              color='secondary'
              className='cancel-btn'
              onClick={onClose}
            >
              Discard
            </Button>
          </Actions>
        </Content>
      )}
    </PopUpForm>
  );
};

export { ZoneForm };
