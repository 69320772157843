import { sendRequest, getRequest, postRequest, putRequest, patchRequest, deleteRequest } from './gateway';
import { Customer } from 'models/Customer';
import {
  BusinessNameResponse,
  Delivery,
  DeliveryListResponse,
  ErrorResponse,
  Vehicle,
  VehicleOperator,
  DeliveryItem,
  Warehouse,
  Business,
  Location,
  ValidateDelivery,
  Zone,
  ApiResponse,
  Pallet,
} from 'models';

const skipSpinnerConfig = {
  skipSpinner: true
};

function fetchCustomers(params: ApiParams) {
  const fetchPromise = getRequest<ApiResponse<Customer[]>>('/v1/customer/customers', {params, ...skipSpinnerConfig});

  return sendRequest(fetchPromise);
}

function fetchCustomersList(params: ApiParams) {
  const fetchPromise = getRequest<ApiResponse<Customer[]>>('/v1/customer/list', {params, ...skipSpinnerConfig});

  return sendRequest(fetchPromise);
}

export interface ApiParams {
  query?: string
  page?: number
  limit?: number
  statuses?: string[]
  fromDate?: string
  toDate?: string
}

type LogPage = {
  collection: any[]; // Adjust the type based on what your collection contains
  metadata: {
    last_page: number;
  };
};

function fetchDeliveries(params: ApiParams) {
  const fetchPromise = getRequest<DeliveryListResponse>('/v1/delivery/list', {
    params, ...skipSpinnerConfig
  });

  return sendRequest(fetchPromise);
}

async function fetchBusinessName() {
  const businessNameResponse = await getRequest<BusinessNameResponse>('/v1/business', {...skipSpinnerConfig});
  const businessDetailResponse = await getRequest<Business>('/admin/api/v1/business/list/me', { skipSpinner: true });
  return {
    businessNameResponse,
    businessDetailResponse
  };
}


function fetchVehicles(params: ApiParams) {
  const fetchPromise = getRequest<Vehicle[]>('/v1/vehicle/list', {params, ...skipSpinnerConfig});

  return sendRequest(fetchPromise);
}

function fetchTransporters(params: ApiParams) {
  const fetchPromise = getRequest<Vehicle[]>('/v1/transporter/list', {params, ...skipSpinnerConfig});

  return sendRequest(fetchPromise);
}

function fetchVehicleOperators(transporter_id: string, params: ApiParams) {
  const fetchPromise = getRequest<VehicleOperator[]>(
    `/v1/user/list?transporter_id=${transporter_id}`, { params, ...skipSpinnerConfig }
  );

  return sendRequest(fetchPromise);
}

function fetchDelivery(delivery_id: string) {
  const fetchPromise = getRequest<Delivery>(`/v1/delivery/${delivery_id}`);

  return sendRequest(fetchPromise);
}

function createDelivery(delivery: Delivery) {
  const postPromise = postRequest<ErrorResponse>('/v1/delivery', delivery);

  return sendRequest(postPromise);
}

function updateDelivery(delivery: Delivery) {
  const putPromise = putRequest<ErrorResponse>(`/v1/delivery/${delivery.delivery_id}`, delivery);

  return sendRequest(putPromise);
}

function updateDeliveryStatus(delivery_id: string, status: string) {
  const putPromise = putRequest<ErrorResponse>(`/v1/delivery/${delivery_id}/${status}`, null);
  return sendRequest(putPromise);
}

function fetchShippingLabel(delivery_id: string) {
  const fetchPromise = getRequest<string>(`/v1/delivery/${delivery_id}/shipping-label`);

  return sendRequest(fetchPromise);
}

function fetchLRReceipt(delivery_id: string) {
  const fetchPromise = getRequest<string>(`/v1/lorry-receipt/${delivery_id}`);
  return sendRequest(fetchPromise);
}

function fetchLogHistory(params: ApiParams, delivery_id): Promise<LogPage> {
  const fetchPromise = getRequest<LogPage>(`/v1/auditlog/list?deliveryId=${delivery_id}`, {params, ...skipSpinnerConfig});
  return sendRequest(fetchPromise);
}

function fetchDeliveryReport(params: ApiParams) {
  const fetchPromise = getRequest<DeliveryItem>('/v1/delivery/report', {params, skipSpinner: false });
  return sendRequest(fetchPromise);
}

function validateDelivery(data: ValidateDelivery) {
  const postPromise = postRequest<ErrorResponse>('/v1/delivery/validate', data);

  return sendRequest(postPromise);
}

function fetchTransactionDetail(params: ApiParams) {
  const getPromise = getRequest<ErrorResponse>('v1/delivery/transactions', { params });
  return sendRequest(getPromise);
}

function createWarehouse({ warehouse_code, warehouse_name }: Warehouse) {
  const postPromise = postRequest<ErrorResponse>('/v1/warehouse', {
    warehouse_code,
    warehouse_name,
  });

  return sendRequest(postPromise);
}

function updateWarehouse({ warehouse_id, warehouse_name }: Warehouse) {
  const putPromise = putRequest<ErrorResponse>(
    `/v1/warehouse/${warehouse_id}`,
    { warehouse_name }
  );

  return sendRequest(putPromise);
}

function fetchWarehouses() {
  const getPromise = getRequest<ErrorResponse>('/v1/warehouse/list');

  return sendRequest(getPromise);
}

function createLocation({
  warehouse_id,
  location_code,
  location_name,
  zone_id,
  notes,
}: Location) {
  const postPromise = postRequest<ErrorResponse>('/v1/location', {
    warehouse_id,
    location_code,
    location_name,
    zone_id,
    notes,
    is_active: true,
  });

  return sendRequest(postPromise);
}

function updateLocation({ location_id, location_name, notes }: Location) {
  const putPromise = putRequest<ErrorResponse>(`/v1/location/${location_id}`, {
    location_name,
    notes,
  });

  return sendRequest(putPromise);
}

function fetchLocations(warehouse_id?: number, zone_id?: number) {
  const query = new URLSearchParams({ 
    ...(warehouse_id && { warehouseId: String(warehouse_id) }),
    ...(zone_id && { zoneId: String(zone_id) })
  }).toString();
  return sendRequest(getRequest<ErrorResponse>(`/v1/location/list?${query}`));
}

function fetchZones(warehouse_id?: number) {
  const warehouseId = warehouse_id ? `?warehouseId=${warehouse_id}`: ''; 
  const getPromise = getRequest<ErrorResponse>(`/v1/zone/list${warehouseId}`);
  return sendRequest(getPromise);
}

function createZone({ zone_code, zone_name, warehouse_id, notes }: Zone) {
  const getPromise = postRequest<ErrorResponse>('/v1/zone', {
    zone_code,
    zone_name,
    warehouse_id,
    notes,
  });
  return sendRequest(getPromise);
}

function updateZone({ warehouse_id, zone_id, zone_name, notes }: Zone) {
  const putPromise = putRequest<ErrorResponse>(`/v1/zone/${warehouse_id}/${zone_id}`, {
    zone_name,
    notes,
  });

  return sendRequest(putPromise);
}

function updateZoneStatus({ warehouse_id, is_active, zone_id }: Zone) {
  const patchPromise = patchRequest<ErrorResponse>(
    `/v1/zone/${warehouse_id}/${zone_id}?isActive=${is_active}`,
    null
  );
  return sendRequest(patchPromise);
}

function updateLocationStatus(location_id: number, is_active: boolean) {
  const patchPromise = patchRequest<ErrorResponse>(
    `/v1/location/${location_id}?isActive=${is_active}`,
    null
  );
  return sendRequest(patchPromise);
}

function fetchPallets(warehouse_id?: number, zone_id?: number, location_id?: number) {
  const urlParams = new URLSearchParams({ 
    ...(warehouse_id && { warehouseId: String(warehouse_id) }),
    ...(zone_id && { zoneId: String(zone_id) }),
    ...(location_id && { locationId: String(location_id) }),
  }).toString();

  const getPromise = getRequest<ErrorResponse>(`/v1/pallet/list?${urlParams}`);
  return sendRequest(getPromise);
}

function createPallet({warehouse_id, location_id, pallet_code, notes, is_empty }: Pallet) {
  const urlParams = new URLSearchParams({ 
    ...(warehouse_id && { warehouseId: String(warehouse_id) }),
    ...(location_id && { locationId: String(location_id) }),
  }).toString();

  const postPromise = postRequest<ErrorResponse>(`/v1/pallet?${urlParams}`, {
    pallet_code,
    notes,
    is_active: true,
    is_empty,
  });
  return sendRequest(postPromise);
}

function updatePallet({warehouse_id, location_id, pallet_id, pallet_code, notes, is_active, is_empty }: Pallet) {
  const putPromise = putRequest<ErrorResponse>(`/v1/pallet/${pallet_id}`, {
    pallet_code,
    notes,
    is_active,
    is_empty,
    warehouse_id,
    location_id,
  });
  return sendRequest(putPromise);
}

function deletePallet(pallet_id: number) {
  const deletePromise = deleteRequest<ErrorResponse>(`/v1/pallet/${pallet_id}`);
  return sendRequest(deletePromise);
}

function updatePalletStatus(pallet_id: number, is_active: boolean) {
  const patchPromise = patchRequest<ErrorResponse>(`/v1/pallet/${pallet_id}?isActive=${is_active}`, null);
  return sendRequest(patchPromise);
}

function generatePalletLabel(pallet_ids: number[]) {
  const postPromise = postRequest<ErrorResponse>('/v1/pallet/pallet-label', { pallet_ids });
  return sendRequest(postPromise);
}

export {
  fetchCustomers,
  fetchDeliveries,
  fetchVehicles,
  fetchVehicleOperators,
  createDelivery,
  fetchDelivery,
  updateDelivery,
  fetchShippingLabel,
  fetchLRReceipt,
  updateDeliveryStatus,
  fetchBusinessName,
  fetchLogHistory,
  fetchTransporters,
  fetchDeliveryReport,
  validateDelivery,
  fetchTransactionDetail,
  createWarehouse,
  updateWarehouse,
  fetchWarehouses,
  createLocation,
  updateLocation,
  fetchLocations,
  fetchZones,
  createZone,
  updateZone,
  updateZoneStatus,
  fetchCustomersList,
  updateLocationStatus,
  fetchPallets,
  deletePallet,
  createPallet,
  updatePallet,
  updatePalletStatus,
  generatePalletLabel,
};
