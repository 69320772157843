import { ErrorResponse } from 'models';
import { getRequest, sendRequest } from './gateway';
import { VisibilityFlags } from 'models';

function fetchConfigurationByName(configurationName:keyof VisibilityFlags) {
  const fetchPromise = getRequest<ErrorResponse>(
    `/v1/business/business-configuration?configurationName=${configurationName}`,
    {
      skipSpinner: true,
    }
  );

  return sendRequest(fetchPromise);
}

export { fetchConfigurationByName };
