import { Box } from '@mui/material';
import styled from '@emotion/styled';
import { FlexColumn, FlexRow } from 'views/components/styled';
import { DeliveryAggregationData } from 'models';
import { DateRangeOptions } from 'views/shared/utils/string-utils';
import { PropsWithChildren } from 'react';

export type CustomDateRangeOptions = DateRangeOptions | 'custom-date' | 'view-all';

export type CustomDateRangeValues =
  | 'Today'
  | 'Last 7 Days'
  | 'Custom Date'
  | 'View All';

export interface DatePickerMenuItem {
  key: CustomDateRangeOptions;
  label: CustomDateRangeValues;
}

export interface DashBoardProps extends PropsWithChildren {
  aggregatedDeliveryData?: DeliveryAggregationData;
  selectedField?: keyof DeliveryAggregationData;
  onChange: (key: string) => void;
}

export interface DashBoardFieldProps {
  field: keyof DeliveryAggregationData;
  value: number;
  selected: boolean;
  onClick: () => void;
}

const DashBoardListTag = styled(FlexRow)({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'stretch',
  width: '100%',
});

const DashBoardFieldTag = styled(DashBoardListTag)({
  gap: '0.4rem',
  paddingBottom: '0.4rem',
  ['&:hover']: {
    cursor: 'pointer',
  },
  ['&.selected']: {
    borderBottom: '2px solid #0F172A',
  },
});

const DashBoardIconHolderTag = styled(Box)({
  padding: '0.6rem',
  borderRadius: '5px',
  ['& svg']: {
    display: 'block',
  },
});

const DashBoardContentTag = styled(FlexColumn)({
  padding: '0.3rem',
  gap: '0.4rem',
  ['& .label']: {
    fontSize: '0.8rem',
    fontWeight: 500,
    color: '#9C9C9C',
  },
  ['& .value']: {
    fontSize: '1rem',
    fontWeight: 500,
    color: '#0F172A',
  },
});

export {
  DashBoardListTag,
  DashBoardFieldTag,
  DashBoardIconHolderTag,
  DashBoardContentTag,
};
