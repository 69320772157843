import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import ListItemText from '@mui/material/ListItemText';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 100,
    },
  },
};

type DropDownFilterProps = {
  onChange: (selectedItems: string[]) => void;
  selectedFields: string[];
  inputFields: string[];
  label?:string
};

export default function DropDownFilter({
  onChange,
  selectedFields,
  inputFields,
  label = 'Status'
}: DropDownFilterProps) {
  const handleChange = (event: SelectChangeEvent<string[]>) => {
    const {
      target: { value },
    } = event;
    const itemsSelected = Array.isArray(value) ? value : value.split(',');
    onChange(itemsSelected);
  };

  return (
    <FormControl sx={{ width: 150 }} size='small'>
      <InputLabel id='delivery-status-label'>{label}</InputLabel>
      <Select
        labelId='delivery-status-label'
        id='delivery-status'
        multiple
        value={selectedFields}
        renderValue={selected => selected.join(', ')}
        input={<OutlinedInput label='Status' />}
        onChange={handleChange}
        MenuProps={MenuProps}
      >
        {inputFields.map(name => (
          <MenuItem key={name} value={name}>
            <Checkbox checked={selectedFields.includes(name)} />
            <ListItemText
              primary={name.charAt(0).toUpperCase() + name.slice(1)}
            />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
