import { useEffect, useRef } from 'react';
import { Outlet } from 'react-router-dom';
import CircularProgress from '@mui/material/CircularProgress';
import { useAccountStore, useConfigurationStore, useSpinnerStore } from 'store';
import { login } from 'services/AccountService';
import TopNavigationBar from 'views/components/nav/TopNavigationBar';
import SideNavigationBar from 'views/components/nav/SideNavigationBar';
import './index.scss';
import { FlexColumn } from 'views/components/styled';
import AppLogout from './AppLogout';

function Layout() {
  const { isAuthenticated, user } = useAccountStore();
  const { spinnerShown } = useSpinnerStore();
  const isInitialFetchCompleted = useRef(false);
  const {
    fetchConfiguration,
    configurations: {
      IsCustomerMasterVisible,
      IsDeliveriesVisible,
      IsWarehouseMasterVisible
    }
  } = useConfigurationStore();

  useEffect(() => {
    (async () => {
      if (!isInitialFetchCompleted.current) {
        await login();
        isInitialFetchCompleted.current = true;

        await fetchConfiguration('IsDeliveriesVisible');
        await fetchConfiguration('IsCustomerMasterVisible');
        await fetchConfiguration('IsWarehouseMasterVisible');
      }
    })();
  }, []);

  if (!isAuthenticated) {
    return (
      <>
        <FlexColumn align='center' size='xl'>
          <iframe
            title='bff-silent-login'
            id='bff-silent-login'
            hidden
          ></iframe>
          <img
            className='logo'
            src='/logo.png'
            alt='logo'
            style={{ marginTop: '10%' }}
          />
          <h1>Authenticating to Aveon Services</h1>
        </FlexColumn>
        {spinnerShown && (
          <div className='spinner'>
            <CircularProgress />
          </div>
        )}
      </>
    );
  }

  if (
    user?.authority &&
    IsCustomerMasterVisible === false &&
    IsDeliveriesVisible === false &&
    IsWarehouseMasterVisible === false
  ) {
    window.location.replace(user.authority);

    return <></>;
  }

  return (
    <AppLogout timeOutInSec={10 * 60}>
      <div className='container'>
        <TopNavigationBar />
        <div className='content-wrapper'>
          <SideNavigationBar />
          <div className='content'>
            <Outlet />
          </div>
        </div>
      </div>
      {spinnerShown && (
        <div className='spinner'>
          <CircularProgress />
        </div>
      )}
    </AppLogout>
  );
}

export default Layout;
