import { Button, Input, Textarea } from 'views/components/elements';
import {
  FormField,
  Required,
  Actions,
  Content,
  PopUpForm,
} from 'views/pages/delivery/StyledComponent';
import {
  removeExtraSpace,
  LocationValidationSchema,
} from 'views/shared/utils/form-validator';
import { LocationFormData } from 'models/Form';
import { Location } from 'models';
import './index.scss';
import '../../delivery/forms/UserForm.scss';
import { ErrorMessage } from 'formik';
import { useLocationStore } from 'store/location';

const initialValues: LocationFormData = {
  location_code: '',
  location_name: '',
  notes: '',
};

interface LocationFormProps {
  edit: boolean;
  value: Location;
  onSubmit: (failed: boolean, error: string, data: Location) => void;
  onClose: () => void;
}

const LocationForm: React.FC<LocationFormProps> = ({
  edit,
  value,
  onSubmit,
  onClose,
}: LocationFormProps) => {
  const { createLocation, updateLocation } = useLocationStore();

  const handleSubmit = async ({
    location_code,
    location_name,
    notes,
  }: LocationFormData) => {
    const locationData = {
      ...value,
      location_code: removeExtraSpace(location_code),
      location_name: removeExtraSpace(location_name),
      notes: removeExtraSpace(notes),
    } as Location;

    const { failed, error, data } = await (edit ? updateLocation : createLocation)(
      locationData
    );

    onSubmit(failed, error, data);
  };

  return (
    <PopUpForm
      className='user-form form'
      initialValues={edit ? value : initialValues}
      validationSchema={LocationValidationSchema}
      validateOnChange={true}
      validateOnBlur={true}
      onSubmit={handleSubmit}
      onReset={onClose}
    >
      {({ errors, setFieldTouched, touched, setFieldValue }) => (
        <Content>
          <FormField className='form-group'>
            <label>
              Location Code <Required>*</Required>
            </label>
            <Input
              name='location_code'
              placeholder='Location Code'
              className={
                touched.location_code && errors.location_code
                  ? 'error-input'
                  : ''
              }
              aria-disabled={edit}
              disabled={edit}
              onChange={event => {
                const { name, value } = event.target;
                setFieldTouched(name, true);
                setFieldValue(name, value);
              }}
            />
            <ErrorMessage
              name='location_code'
              component='span'
              className='error'
            />
          </FormField>
          <FormField className='form-group'>
            <label>
              Location Name <Required>*</Required>
            </label>
            <Input
              name='location_name'
              placeholder='Location Name'
              className={
                touched.location_name && errors.location_name
                  ? 'error-input'
                  : ''
              }
              onChange={event => {
                const { name, value } = event.target;
                setFieldTouched(name, true);
                setFieldValue(name, value);
              }}
            />
            <ErrorMessage
              name='location_name'
              component='span'
              className='error'
            />
          </FormField>
          <FormField className='form-group'>
            <label>Notes</label>
            <Textarea
              name='notes'
              placeholder='Notes'
              className={touched.notes && errors.notes ? 'error-input' : ''}
              onChange={event => {
                const { name, value } = event.currentTarget;
                setFieldTouched(name, true);
                setFieldValue(name, value);
              }}
            />
            <ErrorMessage name='notes' component='span' className='error' />
          </FormField>
          <Actions className='btn-container flex-justify-end'>
            <Button type='submit'>{edit ? 'Update' : 'Create'}</Button>
            <Button
              variant='text'
              color='secondary'
              className='cancel-btn'
              onClick={() => onClose()}
            >
              Discard
            </Button>
          </Actions>
        </Content>
      )}
    </PopUpForm>
  );
};

export default LocationForm;
