import { useWarehouseStore } from 'store/warehouse';
import { ChangeEvent, useEffect, useState } from 'react';
import { VehicleOperator } from 'models';
import {
  SearchableDropDown,
  SearchableDropDownProps,
} from 'views/components/dropdown';
import { useOperatorStore } from 'store/operator';
import { useVehicleStore } from 'store/vehicle';

export function OperatorSearchableDropDown({
  required,
}: {
  required?: boolean;
}) {
  const { setDelivery } = useWarehouseStore();
  const { selectedVehicle } = useVehicleStore();
  const {
    selectedOperator,
    setSelectedOperator,
    vehicleOperatorList,
    fetchVehicleOperator,
  } = useOperatorStore();
  const [options, setOptions] = useState<VehicleOperator[]>([]);

  const handleSearch = async (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const query = event.target.value?.toLowerCase();
    if (!query) {
      return setOptions([]);
    }

    const filteredCreatedOperators = vehicleOperatorList.filter(
      ({ name, phone, email }) =>
        name.toLowerCase().includes(query) ||
        phone.toLowerCase().includes(query) ||
        email.toLowerCase().includes(query)
    );

    const { transporter_id } = selectedVehicle?.transporter ?? {};

    if (!transporter_id) {
      return setOptions([...filteredCreatedOperators]);
    }

    const { failed, data } = await fetchVehicleOperator(transporter_id, {
      query,
    });


    const existingOperators = failed ? [] : data.map((op) => {
      return {
        vehicle_operator_id: (op as any).user_id,
        email: op.email,
        name: op.name,
        phone: op.phone
      };
    });

    setOptions(
      failed
        ? [...filteredCreatedOperators]
        : [...filteredCreatedOperators, ...existingOperators]
    );
  };

  const onChange = (_event: any, newValue: VehicleOperator | null, reason: string) => {
    if (reason === 'clear') {
      setOptions([]);
      setSelectedOperator(null);
      setDelivery({
        vehicle_operator: null,
      });
      return;
    }

    if (newValue) {
      setOptions([newValue]);
      setSelectedOperator(newValue);
      setDelivery({
        vehicle_operator: { ...newValue },
      });
    }
  };

  useEffect(() => {
    setOptions([...vehicleOperatorList]);
  }, [vehicleOperatorList, selectedVehicle]);

  useEffect(() => {
    setOptions(selectedOperator ? [selectedOperator]: []);
  }, [selectedOperator]);

  const props: SearchableDropDownProps<VehicleOperator> = {
    value: selectedOperator,
    required: Boolean(required),
    handleSearch,
    onChange,
    options,
    label: 'Driver',
    debounce: 400,
    isOptionEqualToValue: (option, value) =>
      (option.email && value.email && option.email === value.email) ||
      (option.phone && value.phone && option.phone === value.phone) ||
      option.name === value.name ||
      (option.vehicle_operator_id &&
        value.vehicle_operator_id &&
        option.vehicle_operator_id === value.vehicle_operator_id),
    getOptionLabel: option =>
      `${option.name} - ${option.phone || option.email}`,
    onBlur: () => setOptions(selectedOperator ? [selectedOperator] : []),
  };

  return <SearchableDropDown {...props} />;
}
